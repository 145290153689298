import React from 'react'
import * as classNames from 'classnames'

const ActionButtonLink = ({ onClick, href, children, className}) => {
  return onClick ? (
    <a onClick={onClick} href={href || '#'} {...{className}}>
      {children}
    </a>
  ) : (
    <a {...{href, className}} target="_blank">{children}</a>
  )
}

const ActionButton = ({
  onClick,
  className,
  icon,
  children,
  href,
}) => (
  <ActionButtonLink {...{ onClick, href, children }} className="document-action-link">
    <i className={`fa fa-${icon}`}> </i>
    <span className={classNames('document-action-link-label', className)}>{children}</span>
  </ActionButtonLink>
)

export default ActionButton
