import React from 'react'

import GenList from 'Gen/List/component'
import DocumentSetSummary from 'DocumentSet/Summary/component'

const filters = (userId) => (
  [
    {
      "title": "Filter by Course Type",
      "options": {
        "Aquifer Signature Courses": ["=", "document_sets.kind", 1],
        "Custom Courses": ["=", "document_sets.kind", 0],
        "Favorites": ["and", ["not", ["is_null", "favorites.id"]], ["=", "favorites.user_id", userId || 0]]
      }
    }
  ]
)

export default class DocumentSetList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      favorites: this.props.favorites
    }
  }

  render() {
    const {
      grdu,
      itemsURL,
      pinnedItems,
      userId,
      psas,
    } = this.props

    const {
      favorites
    } = this.state

    return <GenList
      {...{itemsURL, pinnedItems}}
      itemView={(documentSet) => (
        <DocumentSetSummary
          key={documentSet.id}
          {...{documentSet, favorites, grdu}}
          updateFavoritesList={favorites => this.setState({favorites})}
          showEducatorResourceButton={this.props.showEducatorResourceButton}
          educatorResourceId={this.props.educatorResourceId}
          educatorResources={this.props.educatorResources}
          psas={psas}
        />
      )}
      isInfiniteScroll
      search
      genericFilterOptions={filters(userId)}
      coursePageUpgrade={true}
    />
  }
}
