import React from 'react';
import { Provider } from 'react-redux';

import store from 'store/store';
import DocumentList from 'components/Document/List/component';

const DocumentListApp = (props, _railsContext) => (
  () => (
    <Provider store={store()}>
      <DocumentList {...props}/>
    </Provider>
  )
)

export default DocumentListApp;
