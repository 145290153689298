import React from 'react'

export default function GenSortLink(props) {
  const {
    value,
    sortValue,
    sortDirection,
    onClick,
    children='',
  } = props

  let classes = ""
  if (props.sortValue === props.value) {
    if (props.sortDirection === 'ascending') {
      classes =  "sorted asc"
    } else {
      classes = "sorted desc"
    }
  }

  let newDirection = 'descending'
  if (props.sortValue === props.value) {
    newDirection = props.sortDirection === 'descending' ? 'ascending' : 'descending'
  }

  return <a
    href="#"
    className={classes}
    onClick={(e) => {
      e.preventDefault()
      onClick(props.value, newDirection)
    }}>
    {children}
  </a>
}
