import React from 'react'

export default class GenLocalList extends React.Component {
  // PROPS
  // searchKeys: [String],
  // searchValue: String,
  // sortValue: String,
  // sortDirection: String,
  // dataURL: String,
  // dataToItems: data -> items,
  // listView: (items, data) -> views
  // updateTimeInSeconds

  static defaultProps = {
    searchKeys: [],
    searchValue: "",
    sortValue: null,
    sortDirection: null,
    itemsToShow: null,
    dataURL: null,
    dataToItems: null,
  };

  constructor(props, context) {
    super(props, context);
    var defaults = {
      data: null,
    };

    this.state = defaults
  }

  componentDidMount() {
    this.getUpdatedList();

    if ( this.props.updateTimeInSeconds ) {
      this.timer = setInterval(this.getUpdatedList.bind(this), this.props.updateTimeInSeconds * 1000);
    }
  }

  componentWillUnmount() {
    if ( this.timer ) {
      clearInterval(this.timer);
    }
  }

  getPage = (successCallback) => {
    $.ajax({
      type: "GET",
      url: this.props.dataURL,
      dataType: 'json',
      data: {},
      contentType: 'application/json',
      success: successCallback.bind(this),
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  };

  getUpdatedList = () => {
    this.getPage(function(data) {
      this.setState({data: data});
    });
  };

  render() {
    let items = null;
    if (this.state.data) {
      if (this.props.dataToItems) {
        items = this.props.dataToItems(this.state.data)
      } else {
        items = this.state.data
      }
    }

    if (items) {
      if (this.props.searchKeys.length > 0 && this.props.searchValue && this.props.searchValue != "") {
        let lowerCaseSearchValue = this.props.searchValue.toLowerCase()

        items = items.filter((item) => {
          return this.props.searchKeys.map((searchKey) => {
            return item[searchKey].toLowerCase().indexOf(lowerCaseSearchValue) >= 0
          }).reduce((res, item) => {
            return res || item
          })
        })
      }

      if (this.props.sortValue &&  this.props.sortDirection) {
        items = items.sort((a, b) => {
          let compare = a[this.props.sortValue].localeCompare(b[this.props.sortValue])
          if (this.props.sortDirection === 'descending') {
            compare = compare * -1
          }

          return compare
        })
      }

      if (typeof(this.props.itemsToShow) === "number") {
        items = items.slice(0, this.props.itemsToShow)
      }
    }

    return this.props.listView(items, this.state.data)
  }
}
