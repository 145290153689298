import React from 'react'
import { Provider } from 'react-redux'

import store from 'store/store'
import Reports from 'components/Reports'

const ReportsApp = (props, _railsContext) => (
  () => (
    <Provider store={store()}>
      <Reports {...props}/>
    </Provider>
  )
)

export default ReportsApp;
