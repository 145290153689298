import React from 'react'

import GenSelectList from 'Gen/SelectList/component'
import GenButton from 'Gen/Button/component'
import GenPostButton from 'Gen/PostButton/component'

export default class DocumentSetUserPermissionStudentResetPanel extends React.Component {
  state = {
    selectedDocumentSetPermissions : [],
    selectedDocumentSetDocumentRelations : [],
    examRosterLoading: false,
    resetCaseLoading: false,
    downloadNoteLoading: false
  }

  handledDocumentSetDocumentRelationsSelectionChange = (newSelection) => {
    this.setState({selectedDocumentSetDocumentRelations : newSelection})
  }


  onResetCaseSubmitSuccess = () => {
    var editUrl = window.location.href;
    var update_url = editUrl.replace("/document_set_user_permissions/student_reset_panel", "");

    Turbo.visit(update_url);
  }


  handleResetCaseSubmitButton = () => {


    if (this.state.selectedDocumentSetDocumentRelations.length === 0) {
      alert("No Items Selected! You must select at least one item to modify.");
      return(null);
    }
    var documentIds = this.state.selectedDocumentSetDocumentRelations
    let isPrerequisiteCase;

    $.ajax({
      type: 'GET',
      url: `/documents/check_prerequisite_cases`,
      dataType: 'json',
      data: { documentIds: documentIds },
      async: false,
      success: function (data) {
        isPrerequisiteCase = data;
      }
    });

    let confirmReturn;
    if (isPrerequisiteCase) {
      confirmReturn = confirm('(!) Resetting a completed prerequisite case marks it as incomplete and will also re-lock the subservient cases that follow. Click Continue to reset /re-lock cases or Cancel to return to the case listing screen with no changes.')
    } else {
      confirmReturn = confirm("Warning: Item progress will be cleared and reset for all selected item(s). Your faculty and administrators can view each item reset in their reports. You will need to re-take each reset item to record completion. Are you sure you would like to reset?")
    }
    let confirmNoteDownload

    if (confirmReturn != true) {
     return
    }
    else {
      confirmNoteDownload = confirm("Final Warning: All data for the selected item(s) is about to be reset. If you have not downloaded your notes, please select \'Cancel\' and click the \'Download Item Notes\' button.")
      if (confirmNoteDownload != true){
        return
      }
    }


    let data = { sections: {} };

    data.documentIds = this.state.selectedDocumentSetDocumentRelations;

    var editUrl = window.location.href;
    var resetCasesUrl = editUrl.replace("student_reset_panel", "reset_student_cases");

    this.setState({resetCaseLoading: true});

    let outsideThis = this;
    $.ajax({
      type: "PATCH",
      url: resetCasesUrl,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onResetCaseSubmitSuccess,
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        outsideThis.setState({loading: false});
      }
    });
  }

  handleDownloadNoteSubmitButton = () => {


    if (this.state.selectedDocumentSetDocumentRelations.length === 0) {
      alert("No Items Selected! You must select at least one item to download notes from.");
      return(null);
    }


    let confirmReturn = confirm("You are about to download notes for " + this.state.selectedDocumentSetDocumentRelations.length +  " items. ")


    if (confirmReturn != true) {
      return
    }

    let data = { sections: {} };

    data.documentIds = this.state.selectedDocumentSetDocumentRelations;
    var editUrl = window.location.href;
    var downloadNotesUrl = editUrl.replace("student_reset_panel", "download_case_notes");

    this.setState({downloadNoteLoading: true});
    let outsideThis = this;
    $.ajax({
      type: "POST",
      url: downloadNotesUrl,
      dataType: "json",
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: alert("Thank you for downloading your item notes!"),
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        outsideThis.setState({downloadNoteLoading: false});
      }
    });
  }

  render = () => {
    let documentSetPermissionsSelectionView = <GenSelectList
      selected={[this.props.document_set_user_permission]}
      onSelectionChange={this.handleDocumentSetPermssionsSelectionChange}
      itemView={function(role){
        return(
          <div className="document-select-summary" key={role.id}>
            <span>{role.user.email} -- {role.user.first_name} {role.user.last_name}</span>
          </div>);
      }}
      itemsURL={"/document_sets/" + this.props.document_set_id + "/document_set_user_permissions"}
      isInfiniteScroll={true}
      defaultItemsPerPage={30}
      search={true}
      sortOptions={[
                    {
                      "title": "Email",
                      "value": "email",
                    }
                  ]}
    />;

    let documentSetDocumentRelationsSelectionView = <GenSelectList
      permanentFilters={["!=", "documents.kind", 6]}
      selected={this.state.selectedDocumentSetDocumentRelations}
      onSelectionChange={this.handledDocumentSetDocumentRelationsSelectionChange}
      itemView={function(document){
        return(
          <div className="document-select-summary" key={document.id}>
            <span>{document.title}</span>
          </div>);
      }}
      itemsURL={"/document_sets/" + this.props.document_set_id + "/document_set_document_relations"}
      defaultItemsPerPage={30}
      isInfiniteScroll={true}
      search={true}
      sortOptions={[
                    {
                      "title": "Title",
                      "value": "documents.title"
                    }
                  ]}
    />
    var editUrl = window.location.href;
    var downloadNotesUrl = editUrl.replace("student_reset_panel", "download_case_notes");
    return(
      <div className="document-set-user-permission-student-reset-panel">
        <div className="submit-panel">
          <h1>Select Items to Reset</h1>

          <GenButton
            name="Reset Items"
            highlighted={true}
            loading={this.state.resetCaseLoading}
            onClick={this.handleResetCaseSubmitButton}
          />
          <GenPostButton 
            key="SummaryCSV" 
            name="Download Item Notes" 
            highlighted={true} 
            disabled={this.state.selectedDocumentSetDocumentRelations.length === 0} 
            url={downloadNotesUrl} 
            data={{documentIds: this.state.selectedDocumentSetDocumentRelations}} 
          /> 
          <div>
            <br/><br/><p>
            <b>You can choose to reset your items at any time. An Item Reset means that:</b>
            <ul>
              <li>Your progress in the item will be cleared and reset. Your previous data will not be available.</li>
              <li>You will need to re-take the item to record a completion.</li>
              <li>Your faculty and administrators can view each item reset in their reports.</li>
              <li>On your Student Report, you will see a black outline around the circles indicating your progress on the items that have been reset.</li>
            </ul>
            <br/>
            <b>Download Item Notes</b><br/>
            Before resetting an item, be sure to download your notes. There are two ways to do this:
            <ul>
              <li>To download item notes ONLY in a csv file, select items below and click the "Download Item Notes" button.</li>
              <li>To download Item Summaries with your notes included, return to the end of the item(s) and click "Download Item Summary".</li>
            </ul>
            </p>
          </div>
        </div>

        <div className="bottom-panel">

          {documentSetDocumentRelationsSelectionView}
        </div>
      </div>
    )
  }
}
