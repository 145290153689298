import React from 'react'

import _ from 'lodash'

import IconInfoImage from "images/icon-info.png"

import GenLoadingSpinner from 'Gen/LoadingSpinner/component'
import GenSortTable from 'Gen/SortTable/component'
import GenSortLink from 'Gen/SortLink/component'
import GenPercentageCircle from 'Gen/PercentageCircle/component'
import GenResetPercentageCircle from 'Gen/ResetPercentageCircle/component'


import {full_url} from 'helpers/url'

export default function DocumentSetReport(props) {
  let statusHeader = ((props.tab === 'illness_script') ? 'Total # Scripts Accesssed' : 'Cases Completed');
  let documentType = 'Case'
  let illnessColumn = {left: '570px', display: 'none'}
  let caseColumn = {left: '570px'}
  if (props.tab === 'illness_script') {
    illnessColumn = {left: '570px'}
    documentType = 'Script'
    caseColumn = {left: '570px', display: 'none'}
  }
  return (
    <GenSortTable
      searchKeys={["email", "first_name", "last_name"]}
      searchPlaceholder="Search Students..."
      defaultSort="percentages"
      headerView={(state, updateSort) => {
        let documents = state.data.documents
        let documentHeaders = documents.map((document) => {
          let documentTitle = (!!document.report_title) ? document.report_title : document.title
          let documentTileDisplay = _.split(documentTitle, /[\:\.]/)[0]
          return <th className="col_sm" style={{width: '142px',overflowWrap: "break-word"}}><GenSortLink value={"percentages"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}></GenSortLink>
            <a href={"/document_set_document_relations/" + state.data.document_set_document_relation_id_lookup_by_document_id[document.id]  + "/usage_report?" + `${props.start_date ? `&start_date=${props.start_date}` : ""}${props.end_date ? `&end_date=${props.end_date}` : ""}` + "&tab=" + props.tab}>
              {documentTileDisplay}</a><img src={IconInfoImage} alt="case name" className="icon-case_name" title={document.title} />
            </th>
        })

        return <tr className="sticky-row">
          <th className="sticky-cell col_wide"><GenSortLink value={"email"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Email</GenSortLink></th>
          <th className="sticky-cell col_med" style={{left: '250px'}}><GenSortLink value={"first_name"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>First Name</GenSortLink></th>
          <th className="sticky-cell col_med" style={{left: '410px'}}><GenSortLink value={"last_name"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Last Name</GenSortLink></th>
          <th className="sticky-cell col_sm txt-ctr" style={caseColumn}><GenSortLink value={"count"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Cases Completed</GenSortLink></th>
          <th className="sticky-cell col_sm txt-ctr" style={illnessColumn}><GenSortLink value={"script_started"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Total # Scripts Accessed</GenSortLink></th>

          {documentHeaders}
        </tr>
      }}
      rowView={(rowDatum) => {
        let percentage_rows = rowDatum.percentages.map((percentage, index) => {
          let percentComplete
          if (rowDatum.resets[index] == 0){
            percentComplete = <GenPercentageCircle percentage={percentage}/>
          }
          else {
            percentComplete = <GenResetPercentageCircle percentage={percentage}/>
          }
          return <td className="txt-ctr">{percentComplete}</td>
        })

        return <tr>
          <td className="sticky-cell"><a href={"/document_sets/" + props.document_set_id + "/users/"+ rowDatum.id + "/document_set_usage_report?" + `${props.start_date ? `&start_date=${props.start_date}` : ""}${props.end_date ? `&end_date=${props.end_date}` : ""}`}>{rowDatum.email}</a></td>
          <td className="sticky-cell" style={{left: '250px'}}>{rowDatum.first_name}</td>
          <td className="sticky-cell" style={{left: '410px'}}>{rowDatum.last_name}</td>
          <td className="sticky-cell txt-ctr" style={caseColumn}>{rowDatum.count}</td>
          <td className="sticky-cell txt-ctr" style={illnessColumn}>{rowDatum.script_started}</td>


          {percentage_rows}
        </tr>
      }}
      rowDataGenerator={(data) => {
        let documents = data.documents
        let users = data.users
        let percentage_lookup_by_user_and_document = data.percentage_lookup_by_user_and_document
        let reset = data.reset

        return users.map((user) => {
          return {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            resets: documents.map((document) => reset[user.id][document.id]),
            percentages: documents.map((document) => percentage_lookup_by_user_and_document[user.id][document.id]),
            count: percentage_lookup_by_user_and_document[user.id].count,
            script_started: percentage_lookup_by_user_and_document[user.id].script_started
          }
        })
      }}
      dataURL={full_url("/document_sets/" + props.document_set_id + "/usage_report?" + `${props.start_date ? `&start_date=${props.start_date}` : ""}${props.end_date ? `&end_date=${props.end_date}` : ""}` + "&tab=" + props.tab)}
      noRowsMessage='There is no work in this course within the date range selected.'
    />
  );
}
