import React from 'react';

import GenLoadingSpinner from 'Gen/LoadingSpinner/component'

export default class GenLinkButton extends React.Component {
  static defaultProps = {
    highlighted: false,
    disabled: false,
    loading: false
  };

  // name : HTML
  // highlighted : Bool
  // disabled : Bool

  render() {
    let href = this.props.href

    let classes = "gen-button";

    if (this.props.disabled) {
      href = '#'
      classes += " disabled";
    }

    if (this.props.highlighted) {
      classes += " highlighted";
    }

    if (this.props.small) {
      classes += " small";
    }

    if (this.props.className) {
      classes += " "
      classes += this.props.className
    }

    if (this.props.loading) {
      return(
        <div className={classes}>
          <a href={href} className="button-name"><GenLoadingSpinner/></a>
        </div>
      );
    } else {
      return(
        <div className={classes}>
          <a href={href} className="button-name">{this.props.name}</a>
        </div>
      );
    }
  }
}
