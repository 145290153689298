import React from 'react'
import { connect } from 'react-redux'
import { stringify } from 'query-string'
import { map, compact, includes } from 'lodash'
import GenSelectList from 'Gen/SelectList/component'
import GenList from 'Gen/List/component'
import GenButton from 'Gen/Button/component'
import DocumentSummary from 'Summary/component'
import IllnessScriptSummary from 'Document/IllnessScriptSummary/component'
import { full_url } from 'helpers/url'
import { selectedDocumentsSelector, SELECT_CHANGE, ALL_SELECT_CHANGED, REMOVE_DOCUMENT_ID } from 'reducers/documentsList'

import { documentListDefaults } from './defaults'

const sortOptions = (isIllnessScript) => {
  if (isIllnessScript) {
    return [...documentListDefaults.sortOptions, {"title": "Last Accessed", "value": "date_last_accessed"}]
  } else {
    return documentListDefaults.sortOptions
  }
}

const documentIdsQuery = (selectedDocuments) => {
  if (selectedDocuments && selectedDocuments.length > 0) {
    return stringify({ ['document_ids[]']: selectedDocuments })
  }
  return ''
}


const CreateCourseButton = ({selectedDocuments, newDocSetURL}) => (
  <GenButton
    key='create-button'
    name="Create Course"
    highlighted
    small
    confirmMessage="Would you like to create a course containing the selected items?"
    href={compact([newDocSetURL, documentIdsQuery(selectedDocuments)]).join('?')}
  />
)

const caseHeader = (isIllnessScript, isStudentResource, isEducatorResource) => {
  if (isIllnessScript) {
    return "Condition"
  } else if (isStudentResource) {
    return "Student Resource"
  } else if (isEducatorResource) {
    return "Educator Resource"
  } else {
    return "Title"
  }
}


const caseInformationHeader = (isIllnessScript) => {
  if (isIllnessScript) {
    return "Mechanism of Disease Map"
  } else {
    return "Case Information"
  }
}

const TableHeader = (isIllnessScript, isStudentResource, isEducatorResource, useSelectionBasket) => (
  <tr className="document-list-table-header" style={{height: '100%', padding: "0px"}} >
    {useSelectionBasket  && <th style={{width: '90px', padding: "0px"}} />}
    {!useSelectionBasket && <th style={{width: '30px', padding: "0px"}} />}
    <th style={{width: '100px', padding: "0px"}} />
    <th style={{width: '100%', padding: "0px"}} ></th>
    <th style={{width: '250px', padding: "0px"}} ></th>
    {isIllnessScript && <th style={{width: '200px', padding: "0px"}} ></th>}
  </tr>
)

const DocumentList = ({
  filterBar,
  useSelectionBasket,
  createAccess,
  permanentFilters,
  itemsURL,
  itemCountURL,
  newDocSetURL,
  documentSelectedChange,
  removeDocumentId,
  selectAllOrNone,
  selectedDocuments,
  isIllnessScript,
  isStudentResource,
  isEducatorResource,
  caseSummaryURL,
  documentLookupTable,
  enableIntegrations,
}) => {
  const ListComponent = filterBar ? GenSelectList : GenList
  return <div className="document-list">
    <ListComponent
      {...documentListDefaults}
      useSelectionBasket={useSelectionBasket}
      selectedDocuments={selectedDocuments}
      removeDocumentId={removeDocumentId}
      sortOptions={sortOptions(isIllnessScript)}
      {...{documentLookupTable, itemsURL, itemCountURL, permanentFilters}}
      tableHeader={TableHeader(isIllnessScript, isStudentResource, isEducatorResource, useSelectionBasket)}
      itemView={(document) => {
        const SummaryComponent = document.kind === "illness_script" ? IllnessScriptSummary : DocumentSummary
        return <SummaryComponent
          {...{caseSummaryURL, document, isIllnessScript}}
          checked={includes(selectedDocuments, document.id)}
          key={`doc-${document.id}`}
          onClick={documentSelectedChange(document)}
          selectBoxes={filterBar}
          canRemove={document.canRemove}
          enableIntegrations={enableIntegrations}
        />
      }}
      historyPersistance
      selected={selectedDocuments}
      onSelectionChange={selectAllOrNone}
      selectExtraButtons={createAccess && <><div style={{display: "inline-block"}}>Use the Add buttons to add items and create your custom course</div><CreateCourseButton key='create-button' {...{selectedDocuments, newDocSetURL}}/></>}
    />
  </div>
}

const mapStateToProps = (state, oProps) => ({
  selectedDocuments: selectedDocumentsSelector(state)
})

const mapDispatchToProps = (dispatch, oProps) => ({
  removeDocumentId: (documentId) => dispatch({ type: REMOVE_DOCUMENT_ID, documentId }),
  documentSelectedChange: (document) => () => dispatch({ type: SELECT_CHANGE, document, filterBar: oProps.filterBar }),
  selectAllOrNone: (ids) => dispatch({ type: ALL_SELECT_CHANGED, ids, filterBar: oProps.filterBar })
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList)
