import { combineReducers } from 'redux'

import reports from './reports'
import documents from './documentsList'

import documentSummary from './ui/documentSummary'

const ui = combineReducers({
  documentSummary,
})

export default combineReducers({
  ui,
  documents,
  reports,
})
