import React from 'react'
import moment from 'moment'
import * as classNames from 'classnames'
import { connect } from 'react-redux'
import { includes } from 'lodash'
import { selectedDocumentsSelector } from 'reducers/documentsList'
import { TOGGLE_MODAL } from 'reducers/ui/documentSummary'
import GenModal from 'Gen/Modal/component'

const formattedDate = (date) => moment(date).format("MMMM Do YYYY")
const sanitize = (content) => content && content.replace(/^"(.*)"$/, '$1')

const updateBanner = (bannerDate) => {
  if (!bannerDate) return null

  if (moment(bannerDate).isAfter(moment().add(-3, 'months'))) {
    return <div key="update-banner" className='update-banner'>Updated</div>
  }
}

const newBanner = (bannerDate) => {
  if (!bannerDate) return null

  if (moment(bannerDate).isAfter(moment().add(-12, 'months'))) {
    return <div key="update-banner" className='new-banner'>New</div>
  }
}

const ReleaseNotesModal = ({ document, toggleModal }) => (
  <GenModal open >
    <div className="gen-modal-panel">
      <h1>Release Notes</h1>
      { document.last_release_notes && (
        <div dangerouslySetInnerHTML={{__html: sanitize(document.last_release_notes)}} />
      ) || (
        <p>
          Aquifer has recently implemented release notes to communicate significant
          case updates resulting from our commitment to continuous case review and
          improvement. While all cases are reviewed each academic year, this case has
          not yet undergone its scheduled review and update by the course editorial
          board. Release notes will be provided when this has been completed.
        </p>
      )}
      <button onClick={toggleModal(this)}>Close</button>
    </div>
  </GenModal>
)

const DocumentDetail = ({
  document,
  selected,
  selectBoxes,
  checked,
  onClick,
  children,
  toggleModal,
  isIllnessScript,
  isIllnessScriptDocument,
  canRemove,
}) => {
  let authorsAndEditors
  if (isIllnessScriptDocument) {
    authorsAndEditors = [
      document.editor && <p key="editors">{`Lead Editors: ${document.editor}`}</p>,
      document.author && <p key="authors">{`Contributing Authors: ${document.author}`}</p>
    ]
  } else {
    authorsAndEditors = [
      document.author && <p key="authors">{`Author(s): ${document.author}`}</p>
    ]
  }
  const caseUpdated = `Updated: ${formattedDate(document.last_release_date)}`

  const checkboxHidden = { visibility: selectBoxes && canRemove ? 'visible' : 'hidden' }

  const showURL = document.document_set_document_relation_id ? `/document_set_document_relations/${document.document_set_document_relation_id}`: `/documents/${document.id}`

  const onCaseLinkClick = (document) => {

    return (e) => {
      e.stopPropagation();
      const title = $(".breadcrumb-header-panel a")[1].textContent

      if(title == "Student Learning Resources") {
        analytics.track("views_each_student_learning_resource", { page: window.location.href, source: 'Student Learning Resources'})
      } else if (document.kind == 'educator_resource') {
        analytics.track("views_for_each_educator_resource_category", { page: window.location.href, source: 'Educator Resources', case: document.title })
      }

      return true
    }
  }

  let lastAccessed = null
  if (document.progress && document.progress.date_last_accessed) {
    lastAccessed = formattedDate(document.progress.date_last_accessed)
  }

  return (
    <tr className={classNames('gen-select-item', 'document-summary-row', { selected: selected })} onClick={onClick} style={{backgroundColor: document.kind === "illness_script"? '#EDF7FC': ''}}>
      <td className="checkbox col_xsm">
        <a
          disabled
          aria-label={checked ? "Remove" : "Add"}
          style={checkboxHidden}
          className="button small">
          {checked ? 'Remove' : 'Add'}
        </a>
      </td>

      <td className="cover-image">
        <img src={document.cover_image_url} height="75" width="75"/>
        {updateBanner(document.update_banner_date)}
        {newBanner(document.new_banner_date)}
      </td>

      <td className="title">
        <p>
          <a href={showURL} onClick={ onCaseLinkClick(document) }>
            <b dangerouslySetInnerHTML={{__html: document.title}} />
          </a>
        </p>
        {authorsAndEditors}
        <p>
          {caseUpdated}
          <a
            href="#"
            onClick={toggleModal(<ReleaseNotesModal {...{document, toggleModal}}/>, document.id)}
            className="release-notes-button"
          >
            Release Notes
          </a>
        </p>
      </td>

      <td className="docs">
        {children}
      </td>

      {isIllnessScript && <td className="last-accessed-date"> {lastAccessedRowDate(document)} </td>}
    </tr>
  )
}

const lastAccessedRowDate = (document) => {
  if (document.kind === "illness_script" && document.progress) {
    return (document.progress.date_last_accessed) ? formattedDate(document.progress.date_last_accessed) : "Not Yet Accessed"
  }
}

const mapStateToProps = (state, { document }) => ({
  selected: includes(selectedDocumentsSelector(state), document.id),
})

const mapDispatchToProps = (dispatch, oProps) => ({
  toggleModal: (modal, documentId) => (e) => { e.preventDefault(); e.stopPropagation(); dispatch({ type: TOGGLE_MODAL, modal, documentId }) }
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetail)
