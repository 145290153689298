import React from "react"

const Alert = ({ type, typeId, message, dismissCallback }) => {

  const getClassName = (type) => {
    let className = "alert"
    switch (type) {
      case "error":
        className += "-error"
        break;
      case "warning":
        className += "-warning"
        break;
      case "notice":
        className += "-notice"
        break;
      case "success":
        className += "-success"
        break;
      default:
        "-standard"
    }
    return "alert " + className
  }

  const getMessage = (msg) => {
    return msg
  }

  const dismiss = () => {
    dismissCallback(type, typeId)
  }

  return (
    <div className={getClassName(type)}>
      {getMessage(message)}
      <span className="close" onClick={()=>dismiss()}>x</span>
    </div>
  )
}

export default props => <Alert {...props} />
