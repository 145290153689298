import React from 'react'
import * as classNames from 'classnames'
import moment from 'moment'
import { compact, map, some, includes } from 'lodash'

import AquiferBadgeSmallImage from "images/Aquifer-Badge-Small.png"

import {full_url} from 'helpers/url'

const FavoriteStar = ({favorite, kind, toggleFavorite}) => ['custom', 'signature', 'assessment_question'].includes(kind) && (
  <span
    className={classNames('favorite-star', { selected: favorite })}
    onClick={toggleFavorite}
  >
    { favorite ? '★' : '☆' }
  </span>
)

const DocSetLink = ({kind, children, ...props}) => { switch(kind){
  case 'educator_resource':
  case 'student_resource':
  case 'illness_script':
  case 'covid_resource':
    return <a target="_blank" {...props} className="resource" >{children}</a>
  default:
    return <a {...props}>{children}</a>
}}

const Badges = ({
  kind,
  created_by,
  responsible_person,
  start_date,
  end_date,
}) => {
  if (kind !== 'custom') return null

  const badges = compact([
    !!created_by && `Created by: ${created_by.first_name} ${created_by.last_name}`,
    !!responsible_person && `Responsible: ${responsible_person.first_name} ${responsible_person.last_name}`,
    !!start_date && `Start Date: ${ moment(start_date).format('LL') }`,
    !!end_date && `End Date: ${ moment(end_date).format('LL') }`,
  ])

  if(!some(badges)) return null

  let badgeSpans = map(badges, (badgeText, idx) => (
    <span className="course-badge" key={idx}>{badgeText}</span>
  ))

  return <div className="badges">
    {badgeSpans}
  </div>
}

const Lcas = ({ responsibilities, psas, kind }) => {
  let responsible_users = responsibilities
  let lcas
  if (!responsibilities || responsibilities.length === 0) {
    responsible_users = psas
    lcas = responsible_users.map((r) => {
      return `${r.first_name} ${r.last_name} (${r.email})`
    })
  } else {
    lcas = responsible_users.map((r) => {
      return `${r.user.name} (${r.user.email}) as of ${moment(r.created_at).format('LL')}`
    })
  }
  if (!responsible_users || responsible_users.length === 0) return null;

  let label
  switch(responsible_users.length) {
    case 1:
      label = "Lead Course Administrator"
      break
    default:
      label = "Lead Course Administrators"
  }



  let lcaText = ""
  if (kind === 'signature') {
    lcaText = `${label}: ${lcas.join("; ")}`
  }

  let lcaSpan = <span className="course-lcas">{lcaText}</span>

  return <div>
    {lcaSpan}
  </div>
}

export default class DocumentSetSummary extends React.Component {
  isFavorite = () => {
    return includes(this.props.favorites, this.props.documentSet.id)
  }

  toggleFavorite = (e) => {
    e.stopPropagation()

    if (this.isFavorite()) {
      let destroy_url = full_url("/favorites/destroy")
      let data = {document_set_id: this.props.documentSet.id};

      $.ajax({
        type: "DELETE",
        url: destroy_url,
        dataType: 'json',
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: result => this.props.updateFavoritesList(
          this.props.favorites.filter(favorite => favorite !== this.props.documentSet.id)
        ),
        error: function(xhr, status, error) {
          console.log(xhr.responseText);
          console.log(status);
          console.log(error);

          alert("Error: Problem with unfavorite");
        }
      });
    } else {
      let create_url = full_url("/favorites/create")
      let data = {document_set_id: this.props.documentSet.id};

      $.ajax({
        type: "POST",
        url: create_url,
        dataType: 'json',
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: result => this.props.updateFavoritesList(
          this.props.favorites.concat([this.props.documentSet.id])
        ),
        error: function(xhr, status, error) {
          console.log(xhr.responseText);
          console.log(status);
          console.log(error);

          alert("Error: Problem with favorite");
        }
      });
    }
  }

  render() {
    let currentTime = new Date();
    let expiredStyle = {}
    let expireDate = new Date(this.props.documentSet.end_date)
    if (currentTime > expireDate && this.props.documentSet.end_date != null) {
      expiredStyle = {boxShadow: 'inset 0 0 0 300px rgba(0, 0, 0, 0.3)'}
    }
    let cxSubheading = this.props.documentSet.is_cx_doc_set ?
                        <div className="clinical-excellence-subheading" style={{marginBottom: "10px", fontSize: "20px"}}>
                          A Clinical Excellence Case Set
                        </div> : ""
    let educatorResourceButton = this.props.educatorResources != [] && this.props.educatorResources[this.props.documentSet.name] &&
        <a className="button educator-resource-button" href={"/documents/" + this.props.educatorResources[this.props.documentSet.name]}>
          Educator Resources
        </a>

    return (
      <div
        className={classNames('panel', 'summary', this.props.documentSet.kind + '-kind', 'document-set-summary-row')}
        style={expiredStyle}>
        <div style={{display: "table", width: "100%"}}>
          <div style={{display: "table-cell", width: "75%"}}>
            <FavoriteStar
              favorite={this.isFavorite()}
              kind={this.props.documentSet.kind}
              toggleFavorite={this.toggleFavorite}
            />

            <DocSetLink href={"/document_sets/" + this.props.documentSet.id} kind={this.props.documentSet.kind} className="name-and-description">
              <h1 className="name">
                {this.props.documentSet.name}
                {this.props.documentSet.kind === 'signature' && <img className="signature-course-badge" src={AquiferBadgeSmallImage}/>}
                {this.props.documentSet.archived_at && <span key='inactive-warning' style={{color: 'red'}}>Inactive</span>}
              </h1>
              {cxSubheading}
              <p
                className="document-set-description"
                dangerouslySetInnerHTML={{__html: this.props.documentSet.description}}
              >
              </p>
              <Badges {...this.props.documentSet} />
              {/* <Lcas
                {...this.props.documentSet}
                psas={this.props.psas}
                kind={this.props.documentSet.kind}
              /> */}
            </DocSetLink>
          </div>
          {this.props.showEducatorResourceButton && (
            <div style={{display: "table-cell", width: "15%", verticalAlign: "middle"}}>
              {educatorResourceButton}
            </div>
          )}
        </div>
      </div>
    );
  }
}
