import React from 'react'
import moment from 'moment'
import GenModal from 'Gen/Modal/component'
import { connect } from 'react-redux'
import ActionButton from 'Document/ActionButton/component'
import { TOGGLE_MODAL, activeModalSelector, documentIdSelector } from 'reducers/ui/documentSummary'
import { includes } from 'lodash'
import * as classNames from 'classnames';

const ModMapModal = ({ document, toggleModal }) => (
  <GenModal open >
    <div className="gen-modal-panel">
      <h1>Mechanism of Disease Map</h1>
      <b dangerouslySetInnerHTML={{__html: document.title}} />
      <div className="mod-map-image">
        <img src={document.mod_map_image_url} />
      </div>
      <p>
        <button onClick={toggleModal(this)}>Close</button>
      </p>
    </div>
  </GenModal>
)

const formattedDate = (date) => moment(date).format("MMMM Do YYYY")

const updateBanner = (bannerDate) => {
  if (!bannerDate) return null

  if (moment(bannerDate).isAfter(moment().add(-3, 'months'))) {
    return <div key="update-banner" className='update-banner'>Updated</div>
  }
}

const newBanner = (bannerDate) => {
  if (!bannerDate) return null

  if (moment(bannerDate).isAfter(moment().add(-12, 'months'))) {
    return <div key="update-banner" className='new-banner'>New</div>
  }
}

const searchHit = (content) => /search-hit/.test(content)
const sanitize = (content) => content && content.replace(/^"(.*)"$/, '$1')
const synopsisContentSelector = (doc) => sanitize(doc.case_synopsis)

const CaseSynopsisModal = ({ document, toggleModal }) =>
  <GenModal open >
    <div className="gen-modal-panel">
      <h1>Synopsis</h1>
      <b dangerouslySetInnerHTML={{__html: document.title}} />
      <hr/>
      <div dangerouslySetInnerHTML={{__html: synopsisContentSelector(document)}} />
      <button onClick={toggleModal(this)}>Close</button>
    </div>
  </GenModal>

const ReleaseNotesModal = ({ document, toggleModal }) => (
  <GenModal open >
    <div className="gen-modal-panel">
      <h1>Release Notes</h1>
      { document.last_release_notes && (
        <div dangerouslySetInnerHTML={{__html: sanitize(document.last_release_notes)}} />
      ) || (
        <p>
          Aquifer has recently implemented release notes to communicate significant
          case updates resulting from our commitment to continuous case review and
          improvement. While all cases are reviewed each academic year, this case has
          not yet undergone its scheduled review and update by the course editorial
          board. Release notes will be provided when this has been completed.
        </p>
      )}
      <button onClick={toggleModal(this)}>Close</button>
    </div>
  </GenModal>
)

class DocumentSetDocumentRelationSummary extends React.Component {
  render() {
    const {
      document,
      documentId,
      activeModal,
      shouldHideProgress,
      isIllnessScript,
      toggleModal
    } = this.props

    let lockState = false
    if (document.locked || (document.pre_progress && document.pre_progress.percent_complete == 0)) {
      lockState = true
    }
    let progressCell = null
    if (!shouldHideProgress) {
      let progressBar = null
      if (!includes(['student_resource'], document.kind) && document.progress) {
        let percent = Math.round(document.progress.percent_complete)
        let progressText = percent + '%'
        let progress = document.progress.percent_complete + '%'
        progressBar = <div className="progress-bar-outer">
          <div className="progress-bar-padding"><div className="progress-bar-background"><div style={{width: progress}} className="progress-bar"></div></div></div>
          <div className="progress-bar-text">{progressText}</div>
        </div>
      } else {
        progressBar = <p>Not Applicable</p>
      }

      progressCell = <td key="progress-bar">
        {progressBar}
        <span className='avg-time'>Recommended time: {document.recommended_time}</span>
        {lockState && <div className='locked-message'> You must complete the prerequisite case before accessing this case </div>}
      </td>
    }

    let authorsAndEditors
    if (isIllnessScript) {
      authorsAndEditors = [
        document.editor && <p key="editors">{`Lead Editors: ${document.editor}`}</p>,
        document.author && <p key="authors">{`Contributing Authors: ${document.author}`}</p>
      ]
    } else {
      authorsAndEditors = [
        document.author && <p key="authors">{`Author(s): ${document.author}`}</p>
      ]
    }

    const showError = () => { alert("You must complete the prerequisite case before accessing this case."); }
    const caseUpdated = `Updated: ${formattedDate(document.last_release_date)}`
    return (
      <tr className={`gen-select-item document-summary-row ${lockState ? "locked" : ""}`} onClick={lockState ? showError : undefined} style={{backgroundColor: document.kind === "illness_script"? '#EDF7FC': ''}}>
        <td className="cover-image">
            <img src={document.cover_image_url} height="75" width="75"/>
            {updateBanner(document.update_banner_date)}
            {newBanner(document.new_banner_date)}
        </td>
        <td>
          <p><a href={"/document_set_document_relations/" + document.document_set_document_relation_id}><b dangerouslySetInnerHTML={{__html: document.title}}></b></a></p>
          {authorsAndEditors}
          <p>
            {caseUpdated}
            {document.kind === "illness_script" && <a
              href="#"
              onClick={toggleModal(<ReleaseNotesModal {...{document, toggleModal}}/>, document.id)}
              className="release-notes-button">
              Release Notes
            </a>}
          </p>
        </td>
        {progressCell}
        <td>
          <ul className="unstyled">
            {document.kind === "illness_script" && <li>
              <ActionButton onClick={ onSynopsisClick(document, toggleModal) }
                            icon="clipboard"
                            className={classNames({ 'search-hit': searchHit(synopsisContentSelector(document))})}>
                Synopsis
              </ActionButton>
            </li>}
            {document.kind === "illness_script" && <li>
              {document.id === documentId && activeModal}
              {document.mod_map_image_url && (<ActionButton onClick={ onModMapClick(document, toggleModal) } icon="project-diagram">View MOD Map</ActionButton>)}
            </li>}
            <li>
              Last accessed
            </li>
            <li>
              {lastAccessedSummaryDate(document)}
            </li>
          </ul>
        </td>
      </tr>
    )
  }
}

const lastAccessedSummaryDate = (document) => {
  if (document.progress) {
    return (document.progress.date_last_accessed) ? `${formattedDate(document.progress.date_last_accessed)}` : "Not Yet Accessed"
  }
}

const onSynopsisClick = (document, toggleModal) => {
  const toggleModalCallback = toggleModal(<CaseSynopsisModal {...{document, toggleModal}}/>, document.id)

  return (e) => {
    analytics.track("click_on_synopsis", {})
    toggleModalCallback(e)
  }
}

const onModMapClick = (document, toggleModal) => {
  const toggleModalCallback = toggleModal(<ModMapModal {...{document, toggleModal}}/>, document.id)

  return (e) => {
    analytics.track("open_mod_map_from_list_view", { case: document.title, source: 'Integrated Illness Script', page: window.location.href })
    toggleModalCallback(e)
  }
}

const mapStateToProps = (state) => ({
  activeModal: activeModalSelector(state),
  documentId: documentIdSelector(state)
})

const mapDispatchToProps = (dispatch, oProps) => ({
  toggleModal: (modal, documentId) => (e) => { e.preventDefault(); e.stopPropagation(); dispatch({ type: TOGGLE_MODAL, modal, documentId }) }
})

export default connect(mapStateToProps,mapDispatchToProps)(DocumentSetDocumentRelationSummary)
