import React from 'react'

import _ from 'lodash'

import * as classNames from 'classnames'

import GenActionButton from 'Gen/ActionButton/component'
import GenDirectionalButtons from 'Gen/DirectionalButtons/component'
import GenLoadingSpinner from 'Gen/LoadingSpinner/component'
import GenLocalList from 'Gen/LocalList/component'

import {full_url} from 'helpers/url'

export default class DocumentSetProctorDashboard extends React.Component {
  // static defaultProps = {
  //   searchKeys: [],
  //   searchPlaceholder: "Search...",
  //   headerView: null,
  //   rowView: null,
  //   rowDataGenerator: null,
  //   dataURL: null,
  //   exportButtonURL: null,
  // };
  //
  constructor(props, context) {
    super(props, context);
    var defaults = {
      searchValue: "",
      sortValue: null,
      sortDirection: null,
      selectedUserIds: [],
      localListKey: 0,
      isProctorLinkHidden: true
    };

    this.state = defaults
  }

  handleSearchChange = (e) => {
    this.setState({searchValue: e.target.value});
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  updateSort = (newSortValue, newSortDirection) => {
    this.setState({ sortValue: newSortValue,
                    sortDirection: newSortDirection });
  };

  handleSelectionToggle = (userId) => {
    let selectedUserIds = this.state.selectedUserIds
    if (_.includes(selectedUserIds, userId)) {
      _.pull(selectedUserIds, userId)
    } else {
      selectedUserIds.push(userId)
    }

    this.setState({ selectedUserIds: selectedUserIds });
  };

  toggleProctorLink() {
    this.setState({ isProctorLinkHidden: !this.state.isProctorLinkHidden })
  }

  render() {
    let proctor_query_parameter = "?proctor_access_code=" + this.props.proctor_access_code
    let proctor_link = full_url("/document_sets/" + this.props.document_set_id + "/proctor_dashboard" + proctor_query_parameter)

    return <div className="document-set-proctor-dashboard">
      <GenLocalList
        key={this.state.localListKey}
        searchKeys={["email", "first_name", "last_name"]}
        searchValue={this.state.searchValue}
        sortValue={this.state.sortValue}
        sortDirection={this.state.sortDirection}
        dataURL={proctor_link}
        dataToItems={(data) => { return(data.users) }}
        updateTimeInSeconds={30}
        listView={(users, data) => {
          let selectedUsers = []

          let renderedUsers = null
          if (users) {
            selectedUsers = users.filter((user) => (_.includes(this.state.selectedUserIds, user.id)))

            renderedUsers = users.map((user) => {
              let userCell = [<td
                key="0"
                className={classNames({"selected": _.includes(this.state.selectedUserIds, user.id)})}
                onClick={() => this.handleSelectionToggle(user.id)}>{user.last_name + ", " + user.first_name + " - " + user.email}
              </td>]

              if (data.statuses[user.id] == "on_hold") {
                userCell = userCell.concat([<td key="1"></td>, <td key="2"></td>])
              } else if (data.statuses[user.id] === "in_progress") {
                userCell = [<td key="1"></td>].concat(userCell)
                userCell = userCell.concat([<td key="2"></td>])
              } else {
                userCell = [<td key="1"></td>, <td key="2"></td>].concat(userCell)
              }

              return <tr key={user.id} className="document-set-proctor-dashboard-table-user-row">
                {userCell}
              </tr>
            })
          }

          let selectedOnHoldUsers = selectedUsers.filter((user) => (data.statuses[user.id] == "on_hold"))
          let selectedInProgressUsers = selectedUsers.filter((user) => (data.statuses[user.id] == "in_progress"))
          let selectedSubmittedUsers = selectedUsers.filter((user) => (data.statuses[user.id] == "submitted"))

          return <div>
            <div className="document-set-proctor-dashboard-control-panel">
              <form className="document-set-proctor-dashboard-search-bar" role="search">
                <input type="search" placeholder="Search..." value={this.state.searchValue} onChange={this.handleSearchChange} onKeyPress={this.handleSearchKeyPress} />
              </form>

              <GenDirectionalButtons
                buttons={[
                    {
                      "title": "First Name",
                      "value": "first_name"
                    },
                    {
                      "title": "Last Name",
                      "value": "last_name"
                    },
                    {
                      "title": "Email",
                      "value": "email"
                    }
                  ]}
                selectedValue={this.state.sortValue}
                selectedDirection={this.state.sortDirection}
                onUpdate={this.updateSort}
              />
            </div>
          <div className="document-set-proctor-dashboard-users">
              <table className="document-set-proctor-dashboard-table">
                <thead>
                  <tr>
                    <th>Student Roster</th>
                    <th>In Progress</th>
                    <th>Submitted</th>
                  </tr>
                  <tr className="document-set-proctor-dashboard-table-button-row">
                    <th>
                      <GenActionButton
                        name="Start Exams"
                        highlighted={true}
                        disabled={selectedOnHoldUsers.length === 0}
                        type="PATCH"
                        dataURL={full_url("/document_sets/" + this.props.document_set_id + "/batch_start_exam" + proctor_query_parameter)}
                        data={{"user_ids": selectedOnHoldUsers.map((user) => user.id)}}
                        confirmText="Are you sure you want to activate the exam for the student(s) you have selected?"
                        onSuccess={()=>{
                          this.setState({
                            "selectedUserIds": _.pullAll(this.state.selectedUserIds, selectedOnHoldUsers.map((user) => user.id)),
                            "localListKey": this.state.localListKey + 1
                          })
                        }}
                        onError={()=>{
                          console.log(xhr.responseText);
                          console.log(status);
                          console.log(error);
                        }}
                      />
                    </th>
                    <th>
                      <GenActionButton
                        name="Pause Exams"
                        highlighted={true}
                        disabled={selectedInProgressUsers.length === 0}
                        type="PATCH"
                        dataURL={full_url("/document_sets/" + this.props.document_set_id + "/batch_pause_exam" + proctor_query_parameter)}
                        data={{"user_ids": selectedInProgressUsers.map((user) => user.id)}}
                        confirmText="Are you sure you want to pause the exam for the student(s) you have selected?"
                        onSuccess={()=>{
                          this.setState({
                            "selectedUserIds": _.pullAll(this.state.selectedUserIds, selectedInProgressUsers.map((user) => user.id)),
                            "localListKey": this.state.localListKey + 1
                          })
                        }}
                        onError={()=>{
                          console.log(xhr.responseText);
                          console.log(status);
                          console.log(error);
                        }}
                      />
                      <GenActionButton
                        name="End Exams"
                        highlighted={true}
                        disabled={selectedInProgressUsers.length === 0}
                        type="PATCH"
                        dataURL={full_url("/document_sets/" + this.props.document_set_id + "/batch_end_exam" + proctor_query_parameter)}
                        data={{"user_ids": selectedInProgressUsers.map((user) => user.id)}}
                        confirmText="This will end and submit the exam for the student(s) you have selected. You will not be able to undo this action. Are you sure you want to end the exam?"
                        onSuccess={()=>{
                          this.setState({
                            "selectedUserIds": _.pullAll(this.state.selectedUserIds, selectedInProgressUsers.map((user) => user.id)),
                            "localListKey": this.state.localListKey + 1
                          })
                        }}
                        onError={()=>{
                          console.log(xhr.responseText);
                          console.log(status);
                          console.log(error);
                        }}
                      />
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderedUsers}
                </tbody>
              </table>
            </div>
          </div>
        }}
      />
    </div>
  }
}
