import React from 'react'

import GenList from 'Gen/List/component'
import UserSummary from 'Summary/component'

export default function UserList(props) {
  return(
      <GenList
        itemsURL={"/users"}
        itemView={function(user){
          return(<UserSummary user={user} key={user.id}/>);
        }}
        isInfiniteScroll={true}
        search={true}
      />
  );
}
