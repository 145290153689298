import { createSelector } from 'reselect'
import { indexOf, without } from 'lodash';

export const SELECT_CHANGE = 'relier-frontend/documents/SELECT_CHANGE'
export const ALL_SELECT_CHANGED = 'relier-frontend/documents/ALL_SELECTED'
export const REMOVE_DOCUMENT_ID = 'relier-frontend/documents/REMOVE_DOCUMENT_ID'

export const documentsSelector = state => state.documents
export const selectedDocumentsSelector = createSelector(documentsSelector, (r) => r.selectedDocuments)

const initialState = {selectedDocuments: []}

const toggle = (selectedDocIds: number[], doc) => {
  if (indexOf(selectedDocIds, doc.id) >= 0) {
    if (doc.subservient_documents.length > 0) {
      if (selectedDocIds.some( elem => indexOf(doc.subservient_documents.map(newItem => newItem.id), elem) >= 0 )) {
        return [...selectedDocIds]
      } else {
        return without(selectedDocIds, doc.id)
      }
    } else {
      return without(selectedDocIds, doc.id)
    }
  } else {
    if (doc.prerequisite_document && indexOf(selectedDocIds, doc.prerequisite_document.id) < 0) {
      return [...selectedDocIds, doc.id, doc.prerequisite_document.id]
    } else {
      return [...selectedDocIds, doc.id]
    }
  }
}

export default function reducer(state: {selectedDocuments: number[]} = initialState, { type, document, documentId, ids, filterBar }) {
  switch(type) {
    case REMOVE_DOCUMENT_ID:
      return {...state,
        selectedDocuments: without(state.selectedDocuments, documentId),
      }
    case SELECT_CHANGE:
      if (!filterBar) return state

      return {...state,
        selectedDocuments: toggle(state.selectedDocuments, document),
      }
    case ALL_SELECT_CHANGED:
      if (!filterBar) return state

      let combinedIds = state.selectedDocuments.concat(ids)
      let uniqueSet = new Set(combinedIds)
      return {...state,
        selectedDocuments: [...uniqueSet]
      }
    default:
      return state
  }
}
