import React from 'react'

import {full_url} from 'helpers/url'

export default function GroupSummary(props) {
  return (
    <div className="gen-select-item">
      <h1 className="name"><a href={full_url('/', {subdomain: props.group.subdomain})}>{props.group.name}</a></h1>
      {props.group.archived_at && <p><span style={{color: 'red'}}>Inactive</span></p>}
      <p className="description">{props.group.description}</p>
    </div>
  );
}
