import React from 'react'

import _ from 'lodash'

import GenLoadingSpinner from 'Gen/LoadingSpinner/component'
import GenSortTable from 'Gen/SortTable/component'
import GenSortLink from 'Gen/SortLink/component'
import GenYesNoCircle from 'Gen/YesNoCircle/component'

import {full_url} from 'helpers/url'

export default function DocumentSetReport(props) {
  return (
    <GenSortTable
      searchKeys={["email", "first_name", "last_name"]}
      searchPlaceholder="Search Users..."
      headerView={(state, updateSort) => {
        let documentSetHeaders = state.data.document_sets.map((documentSet) => {
          return <th className="col_sm">
            {documentSet.name}
          </th>
        })

        return <tr className="sticky-row">
          <th className="sticky-cell col_wide"><GenSortLink value={"email"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Email</GenSortLink></th>
          <th className="sticky-cell col_med" style={{left: '250px'}}><GenSortLink value={"first_name"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>First Name</GenSortLink></th>
          <th className="sticky-cell col_med" style={{left: '410px'}}><GenSortLink value={"last_name"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Last Name</GenSortLink></th>
          <th className="sticky-cell col_med" style={{left: '570px'}}><GenSortLink value={"role"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Role</GenSortLink></th>
          {documentSetHeaders}
        </tr>
      }}
      rowView={(rowDatum) => {
        return <tr>
          <td className="sticky-cell">{rowDatum.email}</td>
          <td className="sticky-cell" style={{left: '250px'}}>{rowDatum.first_name}</td>
          <td className="sticky-cell" style={{left: '410px'}}>{rowDatum.last_name}</td>
          <td className="sticky-cell" style={{left: '570px'}}>{rowDatum.role_definition_name}</td>
          {rowDatum.document_set_access.map((dsa) => <td className="txt-ctr"><GenYesNoCircle isYes={dsa} /></td>)}
        </tr>
      }}
      rowDataGenerator={(data) => {
        return data.user_infos
      }}
      dataURL={full_url("/group_role_definition_users/usage_report")}
      exportButtonURL={full_url("/group_role_definition_users/usage_report_csv.csv")}
    />
  );
}
