import React from 'react'

import _ from 'lodash'

import GenLoadingSpinner from 'Gen/LoadingSpinner/component'
import GenSortTable from 'Gen/SortTable/component'
import GenSortLink from 'Gen/SortLink/component'
import GenPercentageCircle from 'Gen/PercentageCircle/component'
import GenResetPercentageCircle from 'Gen/ResetPercentageCircle/component'


import {full_url} from 'helpers/url'
import {intToDateString, intToDateTimeString, intTimeToHHMMSS, intTimeToHHMMSSOrHours} from 'helpers/time_format'

export default function DocumentSetDocumentRelationReport(props) {
  let documentType = 'Case'
  let illnessColumn = {display: 'none'}
  let caseColumn = {}
  if (props.tab === 'illness_script') {
    illnessColumn = {}
    documentType = 'Script'
    caseColumn = {display: 'none'}
  }
  return (
    <GenSortTable
      searchKeys={["email", "first_name", "last_name"]}
      searchPlaceholder="Search Students..."
      defaultSort="progress"
      headerView={(state, updateSort) => {
        return <tr className="sticky-row">
          <th className="sticky-cell col_wide"><GenSortLink value={"email"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Email</GenSortLink></th>
          <th className="sticky-cell col_med" style={{left: '250px'}}><GenSortLink value={"first_name"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>First Name</GenSortLink></th>
          <th className="sticky-cell col_med" style={{left: '410px'}}><GenSortLink value={"last_name"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Last Name</GenSortLink></th>
          <th className="txt-ctr col_med"> Time from {documentType} Start to Complete </th>
          <th className="txt-ctr col_sm"><GenSortLink value={"progress"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>% {documentType} Completed</GenSortLink></th>
          <th className="txt-ctr col_sm" style={illnessColumn}>Opened MOD Map in Script</th>
          <th className="txt-ctr col_ex_wide" style={illnessColumn}>Implications for Further Workup</th>
          <th className="txt-ctr col_ex_wide" style={illnessColumn}>Implications for Management</th>
          <th className="txt-ctr col_sm" style={caseColumn}> Pages Completed </th>
          <th className="txt-ctr col_med" style={caseColumn}> Start Date </th>
          <th className="txt-ctr col_med"> Date Last Accessed </th>
          <th className="txt-ctr col_med" style={caseColumn}> Completion Date </th>
          <th className="txt-ctr col_ex_wide" style={caseColumn}> Summary Statement </th>
          <th className="txt-ctr col_ex_wide" style={caseColumn}> Expert Comment </th>
        </tr>
      }}
      rowView={(rowDatum) => {
        const modMapClass = rowDatum.progress.opened_mod_map ? 'mod_map_icon opened' : 'mod_map_icon not_opened'
        let percentComplete
        if (rowDatum.progress.reset ==0){
          percentComplete = <GenPercentageCircle percentage={rowDatum.progress.percent_complete}/>
        }
        else {
          percentComplete = <GenResetPercentageCircle percentage={rowDatum.progress.percent_complete}/>
        }
        return <tr>
          <td className="sticky-cell">{rowDatum.user.email}</td>
          <td className="sticky-cell" style={{left: '250px'}}>{rowDatum.user.first_name}</td>
          <td className="sticky-cell" style={{left: '410px'}}>{rowDatum.user.last_name}</td>
          <td className="txt-ctr">{intTimeToHHMMSS(rowDatum.progress.time_on_case)}</td>
          <td>{percentComplete}</td>
          <td className="txt-ctr" style={illnessColumn}><div className={modMapClass}></div></td>
          <td className="txt-ctr" style={illnessColumn}>{rowDatum.progress.implications_for_further_workup}</td>
          <td className="txt-ctr" style={illnessColumn}>{rowDatum.progress.implications_for_management}</td>
          <td className="txt-ctr" style={caseColumn}>{rowDatum.progress.pages_completed}</td>
          <td className="txt-ctr" style={caseColumn}>{intToDateTimeString(rowDatum.progress.start_date)}</td>
          <td className="txt-ctr">{intToDateTimeString(rowDatum.progress.date_last_accessed)}</td>
          <td className="txt-ctr" style={caseColumn}>{intToDateTimeString(rowDatum.progress.completion_date)}</td>
          <td className="txt-ctr" style={caseColumn}>{rowDatum.progress.summary_statement}</td>
          <td className="txt-ctr" style={caseColumn}>{rowDatum.progress.expert_comment}</td>
        </tr>
      }}
      rowDataGenerator={(data) => {
        let users = data.users
        let progress_lookup_by_user = data.progress_lookup_by_user

        return users.map((user) => {
          return {
            user: user,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            progress: progress_lookup_by_user[user.id]
          }
        })
      }}
      dataURL={full_url("/document_set_document_relations/" + props.document_set_document_relation_id + "/usage_report?" + `${props.start_date ? `&start_date=${props.start_date}` : ""}${props.end_date ? `&end_date=${props.end_date}` : ""}`)}
      exportButtonURL={full_url("/document_set_document_relations/" + props.document_set_document_relation_id + "/usage_report.csv?" + `${props.start_date ? `&start_date=${props.start_date}` : ""}${props.end_date ? `&end_date=${props.end_date}` : ""}`)}
      infoText={"Times shown below are in your local time. Please note that times appear in Eastern time in all exports.<br/>Please note that time spent watching videos is not included in the calculation for Time from " + documentType + " Start to Complete. <a href=\"https://www.aquifer.org/cases-with-videos/\">Learn More...</a>"}
      noRowsMessage='There is no work in this course within the date range selected.'
    />
  );
}
