import React from 'react'

import GenSelectList from 'Gen/SelectList/component'
import GenButton from 'Gen/Button/component'

export default class DocumentSetUserPermissionActionsPanel extends React.Component {
  state = {
    selectedDocumentSetPermissions : [],
    selectedDocumentSetDocumentRelations : [],
    examRosterLoading: false,
    resetCaseLoading: false
  }

  handleDocumentSetPermssionsSelectionChange = (newSelection) => {
    this.setState({selectedDocumentSetPermissions : newSelection})
  }

  handledDocumentSetDocumentRelationsSelectionChange = (newSelection) => {
    this.setState({selectedDocumentSetDocumentRelations : newSelection})
  }

  handleExamRosterSubmitButton = () => {
    if (this.state.selectedDocumentSetPermissions.length === 0) {
      alert("No Users Selected! You must select at least one user to modify.");
      return(null);
    }

    var editUrl = window.location.href;
    var examRosterUrl = editUrl.replace("actions_panel", "exam_roster.csv");
    if (examRosterUrl[examRosterUrl.length-1] === "#"){examRosterUrl = examRosterUrl.substring(0, examRosterUrl.length-1)}

    let queryString = '?' + $.param({ids: this.state.selectedDocumentSetPermissions})

    window.open(examRosterUrl + queryString,'_blank')
  }

  onResetCaseSubmitSuccess = () => {
    var editUrl = window.location.href;
    var update_url = editUrl.replace("/document_set_user_permissions/actions_panel", "");

    Turbo.visit(update_url);
  }

  handleResetCaseSubmitButton = () => {
    if (this.state.selectedDocumentSetPermissions.length === 0) {
      alert("No Users Selected! You must select at least one user to modify.");
      return(null);
    }

    if (this.state.selectedDocumentSetDocumentRelations.length === 0) {
      alert("No Cases Selected! You must select at least one item to modify.");
      return(null);
    }


    let confirmReturn = confirm("You are about to reset " + this.state.selectedDocumentSetDocumentRelations.length +  " cases for " + this.state.selectedDocumentSetPermissions.length + " students. This means the data will no longer be available and the students will need to re-take the case to record completion. Are you sure you would like to reset the cases?")


    if (confirmReturn != true) {
      return
    }

    let data = { sections: {} };

    data.documentSetPermissionIds = this.state.selectedDocumentSetPermissions;
    data.documentIds = this.state.selectedDocumentSetDocumentRelations;

    var editUrl = window.location.href;
    var resetCasesUrl = editUrl.replace("actions_panel", "reset_cases");

    this.setState({resetCaseLoading: true});

    let outsideThis = this;
    $.ajax({
      type: "PATCH",
      url: resetCasesUrl,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onResetCaseSubmitSuccess,
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        outsideThis.setState({loading: false});
      }
    });
  }

  render = () => {
    let documentSetPermissionsSelectionView = <GenSelectList
      selected={this.state.selectedDocumentSetPermissions}
      onSelectionChange={this.handleDocumentSetPermssionsSelectionChange}
      itemView={function(role){
        return(
          <div className="document-select-summary" key={role.id}>
            <span>{role.user.email} -- {role.user.first_name} {role.user.last_name}</span>
          </div>);
      }}
      itemsURL={"/document_sets/" + this.props.document_set_id + "/document_set_user_permissions"}
      isInfiniteScroll={true}
      defaultItemsPerPage={30}
      search={true}
      sortOptions={[
                    {
                      "title": "Email",
                      "value": "email",
                    }
                  ]}
    />;

    let documentSetDocumentRelationsSelectionView = <GenSelectList
      selected={this.state.selectedDocumentSetDocumentRelations}
      onSelectionChange={this.handledDocumentSetDocumentRelationsSelectionChange}
      itemView={function(document){
        return(
          <div className="document-select-summary" key={document.id}>
            <span>{document.title}</span>
          </div>);
      }}
      itemsURL={"/document_sets/" + this.props.document_set_id + "/document_set_document_relations"}
      defaultItemsPerPage={30}
      isInfiniteScroll={true}
      search={true}
      sortOptions={[
                    {
                      "title": "Title",
                      "value": "documents.title"
                    }
                  ]}
    />

    return(
      <div className="document-set-user-permission-actions-panel">
        <div className="submit-panel">
          <h1>Course Management</h1>
          <GenButton
            name="Exam Roster"
            highlighted={true}
            loading={this.state.examRosterLoading}
            onClick={this.handleExamRosterSubmitButton}
          />

        </div>
        <div className="bottom-panel">
          <div className="bottom-sub-panel">
            <div className="bottom-sub-panel-header">
              <h3>Select Cases...</h3>
            </div>
            {documentSetDocumentRelationsSelectionView}
          </div>
          <div className="bottom-sub-panel">
            <div className="bottom-sub-panel-header">
              <h3>Select Users...</h3>
            </div>
            {documentSetPermissionsSelectionView}
          </div>
        </div>
      </div>
    )
  }
}
