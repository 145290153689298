import React from 'react'

import GenPostButton from 'Gen/PostButton/component'

import {formatAsPrice} from 'helpers/number_format'

const discountStyle = {width: "15px", float: "left", position: "sticky", top: "15%", left: "15px"}
const currencyBoxStyle = {overflow: "hidden", width: "150px"}
const discountBoxStyle = {overflow: "hidden", width: "150px", paddingLeft: "13px"}


export default class QuoteInterfaceQuote extends React.Component {
  constructor(props){
    super(props)

    let state = {}
    if (props.quoteInterface.price_category === 3) {
      state = {
        ...state,
        add_price: props.quote.add_price,
        fm_price: props.quote.fm_price,
        ger_price: props.quote.ger_price,
        im_price: props.quote.im_price,
        peds_price: props.quote.peds_price,
        rad_price: props.quote.rad_price,
        woc_price: props.quote.woc_price,
        wmd_price: props.quote.wmd_price,
      }
    }

    this.state = {
      ...state,
      add_price_selected: !!props.quote.add_price,
      fm_price_selected: !!props.quote.fm_price,
      ger_price_selected: !!props.quote.ger_price,
      im_price_selected: !!props.quote.im_price,
      peds_price_selected: !!props.quote.peds_price,
      rad_price_selected: !!props.quote.rad_price,
      woc_price_selected: !!props.quote.woc_price,
      wmd_price_selected: !!props.quote.wmd_price,
      signatureDiscount: props.quote.signature_discount || 0,
      wiseDiscount: props.quote.wise_discount || 0,
      signatureGrant: props.quote.signature_grant || 0,
      wiseGrant: props.quote.wise_grant || 0,
      prorate: props.quote.prorate,
      invoiceStartDate: props.quote.invoice_start_date,
      invoiceEndDate: props.quote.invoice_end_date,
      subStartDate: props.quote.subscription_start_date,
      subEndDate: props.quote.subscription_end_date,
      memo: props.quoteInterface.memo,
    }
  }

  lastSchoolYearDisplay() {
    return `20${this.props.schoolYearStart - 1}-${this.props.schoolYearStart}`
  }

  schoolYearDisplay() {
    return `20${this.props.schoolYearStart}-${this.props.schoolYearStart + 1}`
  }

  schoolYearStartFull() {
    return 2000 + this.props.schoolYearStart
  }

  schoolYearEndFull() {
    return 2000 +  this.props.schoolYearStart + 1
  }

  startOfMonthFromIndex(index) {
    const schoolYearStartFull = this.schoolYearStartFull()
    const schoolYearEndFull = this.schoolYearEndFull()

    return {
      0:`July 1, ${schoolYearStartFull}`,
      1:`August 1, ${schoolYearStartFull}`,
      2:`September 1, ${schoolYearStartFull}`,
      3:`October 1, ${schoolYearStartFull}`,
      4:`November 1, ${schoolYearStartFull}`,
      5:`December 1, ${schoolYearStartFull}`,
      6:`January 1, ${schoolYearEndFull}`,
      7:`February 1, ${schoolYearEndFull}`,
      8:`March 1, ${schoolYearEndFull}`,
      9:`April 1, ${schoolYearEndFull}`,
      10:`May 1, ${schoolYearEndFull}`,
      11:`June 1, ${schoolYearEndFull}`
    }[index]
  }

  endOfMonthFromIndex(index) {
    const schoolYearStartFull = this.schoolYearStartFull()
    const schoolYearEndFull = this.schoolYearEndFull()

    return {
      0:`July 31, ${schoolYearStartFull}`,
      1:`August 31, ${schoolYearStartFull}`,
      2:`September 30, ${schoolYearStartFull}`,
      3:`October 31, ${schoolYearStartFull}`,
      4:`November 30, ${schoolYearStartFull}`,
      5:`December 31, ${schoolYearStartFull}`,
      6:`January 31, ${schoolYearEndFull}`,
      7:`February 28, ${schoolYearEndFull}`,
      8:`March 31, ${schoolYearEndFull}`,
      9:`April 30, ${schoolYearEndFull}`,
      10:`May 31, ${schoolYearEndFull}`,
      11:`June 30, ${schoolYearEndFull}`
    }[index]
  }

  handleProrateCheckbox() {
    let newProrate = !this.state.prorate
    if (newProrate) {
      this.setState({ prorate: !this.state.prorate })
    } else {
      this.setState({
        prorate: !this.state.prorate,
        invoiceStartDate: null,
        invoiceEndDate: null,
        subStartDate: null,
        subEndDate: null
      })
    }
  }

  onSelectChangedISD(e) {
    let newStartDate = parseInt(e.target.value)
    if (this.state.invoiceEndDate && (parseInt(e.target.value) > this.state.invoiceEndDate)) {
      this.setState({ invoiceStartDate: newStartDate, invoiceEndDate: newStartDate + 1 })
    } else {
      this.setState({ invoiceStartDate: newStartDate})
    }
  }

  onSelectChangedIED(e) {
    this.setState({ invoiceEndDate: parseInt(e.target.value) })
  }

  onSelectChangedSSD(e) {
    let newStartDate = parseInt(e.target.value)
    if (this.state.subEndDate && (newStartDate > this.state.subEndDate)) {
      this.setState({ subStartDate: newStartDate, subEndDate: newStartDate + 1 })
    } else {
      this.setState({ subStartDate: newStartDate })
    }
  }

  onSelectChangedSED(e) {
    this.setState({ subEndDate: parseInt(e.target.value) })
  }

  dateDropDown(handlerFunc, value, defaultName, isEnd=false, cutOff=0) {
    return <div style={{display: "inline-block"}}>
      <select onChange={handlerFunc} value={value}>
        <option value="default" selected disabled hidden>{defaultName}</option>
        {[...Array(12).keys()].map((i) => {
          return <option value={i+1} disabled={parseInt(cutOff) > i+1}>{isEnd ? this.endOfMonthFromIndex(i) : this.startOfMonthFromIndex(i)}</option>
        })}
      </select>
    </div>
  }

  handleExceptionPriceChange(priceKey) {
    return (e) => {
      let newPrice = parseInt(e.target.value) || 0
      this.setState({[priceKey]: newPrice})
    }
  }

  handleOnClick(e) { e.stopPropagation() }
  handleMemoChange(e) { this.setState({memo: e.target.value}) }

  dateSelector() {
    let dateSelector
    if (this.props.is_admin) {
      dateSelector = [
        <h6 key='course-dates-intro'>Course dates are outside of normal subscription period:</h6>,
        <input
          key='course-dates-checkbox'
          name="customDateRange"
          type="checkbox"
          onChange={this.handleProrateCheckbox.bind(this)}
          checked={this.state.prorate}
          disabled={this.props.quote.quote_confirmed_at}
        />
      ]

      if (this.state.prorate) {
        dateSelector = dateSelector.concat([
          <p key='course-dates-invoice-par'>Invoice Date Range</p>,
          this.dateDropDown(this.onSelectChangedISD.bind(this), this.state.invoiceStartDate, "Invoice Start Date"),
          this.state.invoiceStartDate && this.dateDropDown(this.onSelectChangedIED.bind(this), this.state.invoiceEndDate, "Invoice End Date", true, this.state.invoiceStartDate),
          <p key='course-dates-subscription-par'>Subscription Date Range</p>,
          this.dateDropDown(this.onSelectChangedSSD.bind(this), this.state.subStartDate, "Subscription Start Date"),
          this.state.subStartDate && this.dateDropDown(this.onSelectChangedSED.bind(this), this.state.subEndDate, "Subscription End Date", true, this.state.subStartDate),
        ])
      }
    } else if (this.state.invoiceStartDate && this.state.invoiceEndDate) {
      dateSelector = [
        <h6>Invoice Date Range</h6>,
        <p>{this.startOfMonthFromIndex(this.state.invoiceStartDate - 1)} - {this.endOfMonthFromIndex(this.state.invoiceEndDate - 1)}</p>
      ]

      if (this.state.subStartDate && this.state.subEndDate) {
        dateSelector.concat([
          <h6>Subscription Date Range</h6>,
          <p>{this.startOfMonthFromIndex(this.state.subStartDate - 1)} - {this.endOfMonthFromIndex(this.state.subEndDate - 1)}</p>
        ])
      }
    }

    return dateSelector
  }

  memo() {
    let memo
    if (this.props.is_admin) {
      memo = [
        <h6 key='memo-title'>Additional Notes:</h6>,
        <textarea key='memo-input' style={{width: "100%"}} type="text" name="business_development_managerComment" onChange={this.handleMemoChange.bind(this)} onClick={this.handleOnClick.bind(this)} defaultValue={this.state.memo || ''} />
      ]
    } else if (this.props.quoteInterface.memo && this.props.quoteInterface.memo.length > 0) {
      memo = [
        <h6 key='memo-title'>Additional Notes:</h6>,
        <p key='memo-text'>{this.props.quoteInterface.memo}</p>
      ]
    }

    return memo
  }

  discountText() {
    if (this.state.prorate) {
      return "*Pro-rated subscriptions are not eligible for multi-course discounts. Additionally CARE (Formerly Aquifer Addiction) subscriptions do not apply towards the Aquifer multi-course discount"
    } else {
      return "*CARE (Formerly Aquifer Addiction) subscriptions do not apply towards the Aquifer multi-course discount"
    }
  }

  dateDisplay() {
    if (this.state.prorate) {
      if (this.state.invoiceStartDate && this.state.invoiceEndDate) {
        return this.startOfMonthFromIndex(this.state.invoiceStartDate - 1) + ' - ' + this.endOfMonthFromIndex(this.state.invoiceEndDate - 1)
      } else {
        return "Custom "
      }
    } else {
      return `July 1, ${this.schoolYearStartFull()} - June 30, ${this.schoolYearEndFull()}`
    }
  }

  render() {
    const {
      addictionCourseNames,
      paidSignatureCourseNames,
      wiseCourseNames,
      freeCourseNames
    } = this.props

    const editUrl = window.location.href
    const downloadPDFUrl = editUrl.replace("edit", "pdf_download.pdf")
    const updateQuoteUrl = editUrl.replace("edit", "update")

    let warning = ''
    if (this.props.is_admin && this.props.new_year_quote_sent) {
      warning = <div style={{backgroundColor: '#B22222', color: 'white', width: '100%', padding: '15px'}}> Warning: There is already a 21-22 quote sent for {this.props.programName} and the default options will have changed. Please be aware that it may need to be reset.</div>
    }

    let isExceptionPriceCategory = this.props.quoteInterface.price_category === 3
    let isCheckboxEnabled = (this.props.is_admin && !this.props.quote.quote_confirmed_at) || (!this.props.quote.quote_submitted_at && !isExceptionPriceCategory && !this.state.prorate)

    let prorateFormula = 1
    let shouldUseProrateFormula = this.state.invoiceStartDate && this.state.invoiceEndDate && !isExceptionPriceCategory
    if (shouldUseProrateFormula) {
      const subscriptionMonths = (this.state.invoiceEndDate - this.state.invoiceStartDate + 1)
      prorateFormula = subscriptionMonths / 12
    }

    ///////////////////
    // PAID COURSES //
    /////////////////
    let addictionCourseSummaries = addictionCourseNames.map(courseName => {
      let courseDetails = this.props.addictionCourseStatuses[courseName] || {}
      let courseKey = courseDetails.key

      let isSelected = this.state[`${courseKey}_selected`]
      let alreadyQuotedThisYear = courseDetails.confirmed

      return {
        courseName: courseName,
        courseKey: courseKey,
        hadPreviously: courseDetails.current,
        alreadyQuotedThisYear: alreadyQuotedThisYear,
        price: alreadyQuotedThisYear && !isSelected ? 0 : ((typeof this.state[courseKey] === 'number') ? this.state[courseKey] : Math.round(this.props.addictionPrice * prorateFormula)),
        isSelected: isSelected
      }
    })
    let addictionCourseSelected = addictionCourseSummaries.filter(summary => summary.isSelected)
    let addictionCourseCounter = addictionCourseSelected.length
    let addictionCourseSubtotal = addictionCourseSelected.reduce((acc, curr) => acc + curr.price, 0)

    let paidSignatureCourseSummaries = paidSignatureCourseNames.map(courseName => {
      let courseDetails = this.props.signatureCourseStatuses[courseName] || {}
      let courseKey = courseDetails.key

      let isSelected = this.state[`${courseKey}_selected`]
      let alreadyQuotedThisYear = courseDetails.confirmed

      return {
        courseName: courseName,
        courseKey: courseKey,
        hadPreviously: courseDetails.current,
        alreadyQuotedThisYear: alreadyQuotedThisYear,
        price: alreadyQuotedThisYear && !isSelected ? 0 : ((typeof this.state[courseKey] === 'number') ? this.state[courseKey] : Math.round(this.props.signaturePrice * prorateFormula)),
        isSelected: isSelected
      }
    })
    let paidSignatureCourseSelected = paidSignatureCourseSummaries.filter(summary => summary.isSelected)
    let paidSignatureCourseCounter = paidSignatureCourseSelected.length
    let paidSignatureCourseSubtotal = paidSignatureCourseSelected.reduce((acc, curr) => acc + curr.price, 0)

    let wiseCourseSummaries = wiseCourseNames.map(courseName => {
      let courseDetails = this.props.wiseCourseStatuses[courseName] || {}
      let courseKey = courseDetails.key

      let isSelected = this.state[`${courseKey}_selected`]
      let alreadyQuotedThisYear = courseDetails.confirmed

      return {
        courseName: courseName,
        courseKey: courseKey,
        hadPreviously: courseDetails.current,
        alreadyQuotedThisYear: alreadyQuotedThisYear,
        price: alreadyQuotedThisYear && !isSelected ? 0 : ((typeof this.state[courseKey] === 'number') ? this.state[courseKey] : Math.round(this.props.wisePrice * prorateFormula)),
        isSelected: isSelected
      }
    })
    let wiseCourseSelected = wiseCourseSummaries.filter(summary => summary.isSelected)
    let wiseCourseCounter = wiseCourseSelected.length
    let wiseCourseSubtotal = wiseCourseSelected.reduce((acc, curr) => acc + curr.price, 0)

    let paidCourseSummaries = addictionCourseSummaries.concat(paidSignatureCourseSummaries, wiseCourseSummaries)
    let paidCourseRows = paidCourseSummaries.map(courseSummary => {
      let priceDisplay
      if (isExceptionPriceCategory && this.props.is_admin && !courseSummary.alreadyQuotedThisYear) {
        priceDisplay = <input style={currencyBoxStyle} type="text" name="add_price" onChange={this.handleExceptionPriceChange(courseSummary.courseKey)} onClick={this.handleOnClick.bind(this)} value={courseSummary.price} />
      } else {
        priceDisplay = formatAsPrice(courseSummary.price)
      }

      return <tr key={courseSummary.courseName}>
        <td style={{backgroundColor: 'white'}}>{courseSummary.courseName}</td>
        <td style={{backgroundColor: 'white', textAlign: "center"}}>
          <input
            type="checkbox"
            disabled={!isCheckboxEnabled}
            checked={this.props.isAddendum ? courseSummary.alreadyQuotedThisYear : courseSummary.hadPreviously}
          />
        </td>
        <td style={{backgroundColor: '#BAECFF', textAlign: "center"}}>
          <input
            type="checkbox"
            disabled={!isCheckboxEnabled || courseSummary.alreadyQuotedThisYear}
            onClick={isCheckboxEnabled && !courseSummary.alreadyQuotedThisYear && (() => this.setState({[`${courseSummary.courseKey}_selected`]: !this.state[`${courseSummary.courseKey}_selected`]}))}
            checked={courseSummary.isSelected || courseSummary.alreadyQuotedThisYear}
          />
        </td>
        <td style={{backgroundColor: 'white'}}>{priceDisplay}</td>
      </tr>
    })

    let paidCoursesTable = <table>
      <tbody>
        <tr key='header-row'>
          <th style={{width: "40%"}} > Course Name </th>
          <th style={{width: "15%"}} > {this.props.isAddendum ? this.schoolYearDisplay() : this.lastSchoolYearDisplay()} Subscriptions </th>
          <th style={{width: "15%"}} > {this.schoolYearDisplay()} Subscriptions </th>
          <th style={{width: "30%"}} > {`${this.schoolYearDisplay()} Price`} </th>
          <th></th>
        </tr>
        {paidCourseRows}
      </tbody>
    </table>

    ///////////////////
    // FREE COURSES //
    /////////////////
    let freeCourseRows = freeCourseNames.map((courseName) => {
      let courseDetails = this.props.freeCourseStatuses[courseName]

      return <tr key={courseName}>
        <td style={{backgroundColor: 'white'}}>{courseName}</td>
        <td style={{backgroundColor: 'white', textAlign: "center"}}>
          <input
            type="checkbox"
            disabled={!isCheckboxEnabled}
            checked={this.props.isAddendum || courseDetails.current}
          />
        </td>
        <td style={{backgroundColor: '#BAECFF', textAlign: "center"}}>
          <input
            name="selected"
            type="checkbox"
            disabled={!isCheckboxEnabled}
            checked
          />
        </td>
        <td style={{backgroundColor: 'white'}}>Free with your subscription</td>
      </tr>
    })

    let freeCoursesTable = <table>
      <tbody>
        <tr key='header-row' style={{visibility: 'collapse'}}>
          <th style={{width: "40%"}} ></th>
          <th style={{width: "15%"}} ></th>
          <th style={{width: "15%"}} ></th>
          <th style={{width: "30%"}} ></th>
          <th></th>
        </tr>
        {freeCourseRows}
      </tbody>
    </table>

    let subtotal = addictionCourseSubtotal + paidSignatureCourseSubtotal + wiseCourseSubtotal

    let wiseDiscount = this.state.wiseDiscount
    let signatureDiscount = this.state.signatureDiscount
    if (!this.props.isAddendum && !this.state.prorate && !isExceptionPriceCategory) {
      if (this.props.quoteInterface.price_category === 1 && paidSignatureCourseCounter > 1) {
        signatureDiscount = 1000 * (paidSignatureCourseCounter - 1)
      } else if (this.props.quoteInterface.price_category === 2 && paidSignatureCourseCounter > 1) {
        signatureDiscount = .05 * (paidSignatureCourseCounter) * paidSignatureCourseSubtotal
      } else {
        signatureDiscount = 0
      }

      if (this.props.quoteInterface.price_category === 1 && wiseCourseCounter === 2) {
        wiseDiscount = 1000
      } else if (this.props.quoteInterface.price_category === 2 && wiseCourseCounter === 2) {
        wiseDiscount = 2 * this.props.wisePrice * .1
      } else {
        wiseDiscount
      }
    }

    let wiseDiscountView
    let signatureDiscountView
    if (this.props.is_admin && (this.state.prorate || this.props.isAddendum || isExceptionPriceCategory)) {
      wiseDiscountView = <div style={{overflow: "hidden"}}><div style={discountStyle}>-$</div><input style={discountBoxStyle} type="text" name="wiseDiscount" onChange={this.handleExceptionPriceChange('wiseDiscount')} value={this.state.wiseDiscount} /></div>
      signatureDiscountView = <div style={{overflow: "hidden"}}><div style={discountStyle}>-$</div><input style={discountBoxStyle} type="text" name="signatureDiscount" onChange={this.handleExceptionPriceChange('signatureDiscount')} value={this.state.signatureDiscount} /></div>
    } else {
      wiseDiscountView = <div style={{paddingLeft: '16px'}}>-{formatAsPrice(wiseDiscount)}</div>
      signatureDiscountView = <div style={{paddingLeft: '16px'}}>-{formatAsPrice(signatureDiscount)}</div>
    }

    let signatureGrant = this.state.signatureGrant
    let wiseGrant = this.state.wiseGrant

    let wiseGrantView
    let signatureGrantView
    if (this.props.isAddendum){
      if (this.props.is_admin) {
        wiseGrantView = <div style={{overflow: "hidden"}}><div style={discountStyle}>-$</div><input style={discountBoxStyle} type="text" name="wiseGrant" onChange={this.handleExceptionPriceChange('wiseGrant')} value={wiseGrant} /></div>
        signatureGrantView = <div style={{overflow: "hidden"}}><div style={discountStyle}>-$</div><input style={discountBoxStyle} type="text" name="signatureGrant" onChange={this.handleExceptionPriceChange('signatureGrant')} value={signatureGrant} /></div>
      } else {
        if (wiseGrant > 0) {
          wiseGrantView = <div style={{paddingLeft: '16px'}}>-{formatAsPrice(wiseGrant)}</div>
        }

        if (signatureGrant > 0) {
          signatureGrantView = <div style={{paddingLeft: '16px'}}>-{formatAsPrice(signatureGrant)}</div>
        }
      }
    }

    let total = subtotal - signatureDiscount - wiseDiscount - signatureGrant - wiseGrant

    let buttonData = {
      quote: {
        signature_discount: signatureDiscount,
        wise_discount: wiseDiscount,
        signature_grant: signatureGrant,
        wise_grant: wiseGrant,
        subtotal: subtotal,
        total: total,
        prorate: this.state.prorate,
        invoice_start_date: this.state.invoiceStartDate,
        invoice_end_date: this.state.invoiceEndDate,
        subscription_start_date: this.state.subStartDate,
        subscription_end_date: this.state.subEndDate,
      },
      memo: this.state.memo,
    }

    paidCourseSummaries.forEach(courseSummary => {
      buttonData.quote[courseSummary.courseKey] = courseSummary.isSelected ? courseSummary.price : null
    })

    let renewalStage
    if (this.props.quote.quote_confirmed_at) {
      renewalStage = 'Quote Confirmed'
    } else if (this.props.quote.quote_submitted_at) {
      renewalStage = 'Ready to Confirm'
    } else if (this.props.quote.quote_sent_at) {
      renewalStage = 'Quote Sent'
    } else {
      renewalStage = 'Ready to Send'
    }

    let button
    if (renewalStage === 'Quote Sent') {
      button = <GenPostButton
        key="submitRenewal"
        name="Submit My Order"
        highlighted
        url={updateQuoteUrl}
        data={{
          saveType: 'SUBMIT',
          ...buttonData
        }}
      />
    } else if (renewalStage === 'Ready to Confirm') {
      if (this.props.is_admin) {
        button = <GenPostButton
          key="confirmRenewal"
          name="Confirm Quote"
          highlighted
          url={updateQuoteUrl}
          data={{
            saveType: 'CONFIRM',
            ...buttonData
          }}
        />
      } else {
        button = <GenPostButton
          key="submittedRenewal"
          name="Order Submitted"
          disabled
        />
      }
    } else if (renewalStage === 'Quote Confirmed') {
      button = <GenPostButton
        key="confirmedRenewal"
        name="Order Confirmed"
        disabled
      />
    }

    return <div className="document-set-user-permissions-select-list">
      {warning}
      <div className="submit-panel">
        <h1>Aquifer Quote</h1><br/><br/><br/>
        <h2 style={{color: "#0095c9"}}>{this.props.programName}</h2>
        <h3 style={{fontSize: "25px"}}>{this.dateDisplay()} Subscriptions</h3>
        <hr/>
        <table>
          <tbody>
            <tr>
              <td><b>Program Service Administrator</b><br/>{this.props.quoteInterface.psa_name}<br/>{this.props.quoteInterface.psa_email}</td>
              <td><b>Billing Contact</b><br/>{this.props.quoteInterface.billing_contact_name}<br/>{this.props.quoteInterface.billing_contact_email}</td>
              <td><b>Aquifer Relationship Manager</b><br/>{this.props.quoteInterface.business_development_manager}<br/>{this.props.quoteInterface.business_development_manager_email}</td>
            </tr>
          </tbody>
        </table>
        <p>By completing this form you are indicating which Aquifer courses your program wishes to subscribe to for the {this.schoolYearEndFull()} subscription year which runs from {this.dateDisplay()}.</p>
        <p>Once you press the 'Submit My Order' button your selections will be submitted to your Aquifer Relationship Manager for review.  After your submission has been reviewed you will receive an email confirming your order with a final .pdf of your confirmed quote, and an invoice will be sent to you within 10 business days.</p>
        <p>If you have any questions or have submitted your order in error please see our Subscription FAQs or email your Aquifer Relationship Manager at the address above.</p>

        {this.dateSelector()}
        {this.memo()}
      </div>
      <div className="submit-panel">
      <div>
        {paidCoursesTable}
        {freeCoursesTable}
      </div>
        <table style={{marginTop: '-10px'}}>
          <tbody>
            <tr style={{visibility: 'collapse'}}>
              <th style={{width: "30%"}} ></th>
              <th style={{width: "25%"}} ></th>
              <th style={{width: "15%"}} ></th>
              <th style={{width: "30%"}} ></th>
            </tr>
            <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}><b>SubTotal</b></td>
              <td style={{backgroundColor: '#BAECFF'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${(subtotal || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              <td></td>
            </tr>
            <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}>Aquifer Multi-Course Discount*</td>
              <td style={{backgroundColor: '#BAECFF'}}>{signatureDiscountView}</td>
              <td></td>
            </tr>
            <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}>WISE Multi-Course Discount</td>
              <td style={{backgroundColor: '#BAECFF'}}>{wiseDiscountView}</td>
              <td></td>
            </tr>
            {signatureGrantView && <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}>Aquifer Grant</td>
              <td style={{backgroundColor: '#BAECFF'}}>{signatureGrantView}</td>
              <td></td>
            </tr>}
            {wiseGrantView && <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}>WISE Grant</td>
              <td style={{backgroundColor: '#BAECFF'}}>{wiseGrantView}</td>
              <td></td>
            </tr>}
            <tr className={'gen-select-item'}>
              <td style={{backgroundColor: '#E8E8E8'}}></td>
              <td style={{backgroundColor: '#E8E8E8', textAlign: "right"}}><b>{this.schoolYearDisplay()} Subscription Total</b></td>
              <td style={{backgroundColor: '#1B5599', color: 'white'}}><b>&nbsp;&nbsp;&nbsp;&nbsp;${(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b></td>
              <td style={{backgroundColor: '#E8E8E8'}}></td>
            </tr>
            <tr>
              <td colSpan={2}><i>{this.discountText()}</i></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={2}>{this.props.is_admin && <GenPostButton
                key="saveQuote"
                name="Save Quote"
                highlighted
                disabled={renewalStage === 'Quote Confirmed'}
                url={updateQuoteUrl}
                data={{
                  ...buttonData
                }}
              />}</td>
              <td colSpan={2}>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>{button}</td>
              <td colSpan={2}>
                <div style={{float: 'left'}}>
                  <GenPostButton
                    key="SummaryPDF"
                    name="Download Quote PDF"
                    highlighted
                    url={downloadPDFUrl}
                    data={{
                      access_code: this.props.quoteInterface.access_code,
                      ...buttonData
                    }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }
}
