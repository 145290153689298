import React from 'react'

import GenSelectList from 'Gen/SelectList/component'
import GenButton from 'Gen/Button/component'
import 'react-dates/initialize'
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates'


export default class DocumentSetUserPermissionCreateSelectList extends React.Component {
  state = {
    selectedGroupRoleDefinitionUserIds : [],
    loading: false,
    inviteDate: null,
    focused: null
  };

  handleGroupRoleSelectionChange = (newSelection) => {
    this.setState({selectedGroupRoleDefinitionUserIds : newSelection});
  };

  onSubmitSuccess = () => {
    var edit_url = window.location.href;
    var update_url = edit_url.replace("/new_batch_list", "");

    alert("Members were successfully added to the course.")
    Turbo.visit(update_url);
  };

  handleError = ({responseJSON}) => {
    this.setState({loading: false})
    if (responseJSON && responseJSON.length > 0) {
      const inviteCount = this.state.selectedGroupRoleDefinitionUserIds.length
      alert(`${responseJSON.length} of ${inviteCount} members were not invited:\n${responseJSON.join('\n')}`)
    } else {
      alert("An unknown error occurred.  Please try again or contact Aquifer support.")
    }
  }

  handleDateChange = (date) => {
    this.setState({
      inviteDate: date
    });
  }

  handleSubmitButton = () => {
    if (this.state.selectedGroupRoleDefinitionUserIds.length === 0) {
      alert("No Members Selected! You must select at least one user to add from list.");
      return(null);
    }

    let data = {groupRoleDefinitionUserIds: this.state.selectedGroupRoleDefinitionUserIds, inviteDate: this.state.inviteDate};
    let edit_url = window.location.href;
    let update_url = edit_url.replace("new_", "create_");

    this.setState({loading: true});

    let outsideThis = this;
    $.ajax({
      type: "POST",
      url: update_url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onSubmitSuccess,
      error: this.handleError,
     });
  };

  render() {
    let groupRoleSelectionView = <GenSelectList
      selected={this.state.selectedGroupRoleDefinitionUserIds}
      onSelectionChange={this.handleGroupRoleSelectionChange}
      itemView={(role) => (
        <div
          key={role.id}
          role="button"
          tabIndex="0"
          aria-label={role.user.email}
        >
          {role.user.email} -- {role.user.first_name} {role.user.last_name}
        </div>
      )}
      itemsURL={"/group_role_definition_users"}
      isInfiniteScroll
      search
      sortOptions={[
                    {
                      "title": "First Name",
                      "value": "first_name",
                    },
                    {
                      "title": "Last Name",
                      "value": "last_name"
                    },
                    {
                      "title": "Email",
                      "value": "email"
                    }]}
    />;

    const memberCount = this.state.selectedGroupRoleDefinitionUserIds.length > 0 ?
      this.state.selectedGroupRoleDefinitionUserIds.length :
      ''

    return(
      <div className="document-set-user-permissions-select-list">
        <div className="submit-panel">
          <h1>Add Members</h1>
          <div className="schedule-invites">
            <div className="schedule-invites-label">
              <div><b>Schedule invite emails</b></div>
              <div className="schedule-invites-hint">
                <small>(leave blank to send invites immediately)</small>
              </div>
            </div>
            <div className="schedule-invites-fields">
              <SingleDatePicker
                date={this.state.inviteDate}
                onDateChange={this.handleDateChange}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                id="dus_datepicker"
                small={false}
                readOnly={true}
              />
              <GenButton
                name={`Add ${memberCount} Member${memberCount == 1 ? '' : 's'}`}
                highlighted={true}
                loading={this.state.loading}
                onClick={this.handleSubmitButton}
              />
            </div>
          </div>
        </div>
        <div>
          {groupRoleSelectionView}
        </div>
      </div>
    );
  }
}
