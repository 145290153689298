import React from "react";
import ProgressBar from "./progress_bar";
import { sortImage, sortQuizzes } from "./sorting_table";

export default class SaqClinicalFocus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      filteredData: this.props.clinical_focuses,
      sortCol: "number_of_items",
      sortDir: "asc",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateCollapsedState);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCollapsedState);
  }

  updateCollapsedState = () => {
    const isSmallScreen = window.innerWidth < 768;
    this.setState({ collapsed: isSmallScreen });
  };

  handleSort = (sortCol, sortDir) => {
    const isCertainty = sortCol === 'certainty_percentage';
    this.setState({
      sortCol,
      sortDir,
      filteredData: sortQuizzes(
        this.state.filteredData,
        sortCol,
        sortDir,
        false,
        isCertainty
      ),
    });
  };

  render() {
    return (
      <>
        <div className="performanceQuizCard focusArea">
          <div className="performanceQuizCardTitleRow filter-label-focus" tabIndex="0"
              onClick={() => {
                this.setState({ collapsed: !this.state.collapsed });
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.setState({ collapsed: !this.state.collapsed });
                }
              }}
            >
            <div
              className={
                this.state.collapsed
                  ? "saqResultsCardOpen"
                  : "saqResultsCardClosed"
              }
            ></div>
            <h2 className="saqResultsCardTitle1">CLINICAL FOCUS</h2>
            <h6 className="saqResultsCardTitle2 d-none">Identify Focus Areas: Clinical Focus</h6>
          </div>
          <table
            className="performanceQuizCardTable"
            style={{ display: this.state.collapsed ? "none" : "table" }}
          >
            <thead>
              <tr>
                <th width="25%">CLINICAL FOCUS</th>
                <th width="30%">
                  QUESTIONS COMPLETED &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "completed_questions",
                    this.handleSort
                  )}
                </th>
                <th width="20%">
                  ACCURACY &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "accuracy_percentage",
                    this.handleSort
                  )}
                </th>
                <th width="25%">
                  CERTAINTY &nbsp;
                  {sortImage(
                    this.state.sortCol,
                    "certainty_percentage",
                    this.handleSort
                  )}
                  <div className="info-bubble">
                    <span className="tooltip-box">
                      Dark Purple = Very Certain
                      Medium Purple = Certain
                      Light Purple = Uncertain
                      Gray = Very Uncertain                     
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.clinical_focuses.map((clinical_focus, index) => (
                <tr key={index}>
                  <td width="25%">
                    {clinical_focus.name}
                    <div className="d-none">
                      {clinical_focus.completed_questions}/{clinical_focus.total_questions}
                      <span>questions</span>
                    </div>
                  </td>
                  <td width="30%">
                    {clinical_focus.completed_questions}/
                    {clinical_focus.total_questions}
                  </td>
                  <td width="20%">{clinical_focus.accuracy_percentage}%</td>
                  <td width="25%">
                    <ProgressBar percent={clinical_focus.certainty_percentage} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
