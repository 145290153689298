import React, { useState } from "react"

const FilterableList = ({ initial, items = [], filterChecks = [], staticData, selectCallback, removeCallback, confirmCallback }) => {
  const [current, setCurrent] = useState(initial)
  const [filtered, setFiltered] = useState(items)
  const [visible, setVisible] = useState(false)

  const filterList = (event) => {
    setFiltered(items.filter((v) => {
      const checks = []
      for (const fc of filterChecks) {
        checks.push(v[fc].toLowerCase().includes(event.target.value.toLowerCase()))
      }
      return checks.includes(true)
    }))
  }

  async function confirmSelect(item) {
    if (confirmCallback) {
      confirmCallback(item, handleConfirmation)
    } else
    {
      handleSelect(item)
    }
  }

  const handleConfirmation = (item, shouldContinue) => {
    if(shouldContinue) {
      handleSelect(item)
    } else
    {
      hideList()
    }
  }

  async function handleSelect(item) {
    if (selectCallback) {
      const isOk = await selectCallback(item, staticData)
      if (isOk) {
        setCurrent(item)
      }
    } else {
      setCurrent(item)
    }
    hideList()
  }

  const clearSelection = (item) => {
    if (removeCallback) {
      removeCallback(item, staticData)
    }
    setCurrent(null)
    setFiltered(items)
  }

  const revealList = () => {
    setVisible(true)
  }

  const hideList = () => {
    setVisible(false)
  }

  const getValue = () => {
    if (current) {
      return <div className={`current`}>{current.displayValue}<span className="close" onClick={()=>clearSelection(current)}>{"x"}</span></div>
    } else {
      return <input type="text" className={`filter-input`} placeholder={"Search"} onFocus={revealList} onInput={filterList} />
    }
  }

  return (
    <div className="filterable-list-wrapper">
      {getValue()}
      <ul className={visible ? "filterable-list" : "hidden filterable-list"}>
      <span className="close" onClick={hideList}>{"^"}</span>
      {filtered.map(item => {
        return (
          <li className="filterable-list-item" key={item.id} onClick={()=>confirmSelect(item)}>{item.displayValue}</li>
        )
      })}
      </ul>
    </div>
  )
}

export default props => <FilterableList {...props} />
