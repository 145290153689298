import React from 'react'
import { detect } from 'lodash'
import { connect } from 'react-redux'
import GenButton from 'Gen/Button/component'
import {
  CREATE,
  DOWNLOAD,
  isLoadingSelector,
  errorSelector,
  urlSelector,
  reportTypeSelector,
} from 'reducers/reports'

const Reports = ({
  createUrl,
  showUrl,
  reportTypes,

  isLoading,
  error,
  url,
  createReport,
  downloadReport,
  reportType,
}) => (
  <div>
    { isLoading && (
      <GenButton
        loadingName={`Preparing ${reportType.label} report.  Please wait.`}
        loading
        highlighted
      />
    ) || url && (
      <a className="button" href={url}>
        Download {reportType.label} Report
      </a>
    ) || (
      <span>
        { reportTypes.map((rtype) => (
          <button
            key={rtype.type}
            className="list-item"
            onClick={createReport(rtype)}
          >
            {rtype.label}
          </button>
        )) }
      </span>
    )}
    { error && (
      <div>There was an unknown error.  Please try again or contact support.</div>
    )}
  </div>
)

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector(state),
  url: urlSelector(state),
  error: errorSelector(state),
  reportType: reportTypeSelector(state),
})

const mapDispatchToProps = (dispatch, oProps) => ({
  createReport: (reportType) => () => dispatch({ type: CREATE, ...oProps, reportType }),
  downloadReport: () => dispatch({ type: DOWNLOAD }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
