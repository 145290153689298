import React from 'react'

export default function UserSummary(props) {
  return (
    <div className="panel summary">
      <h1 className="full_name">
        <a href={"/users/" + props.user.id}>
          <span className="first_name">{props.user.first_name}</span> <span className="last_name">{props.user.last_name}</span>
        </a>
      </h1>
      <p className="email">{props.user.email}</p>
    </div>
  );
}
