import React from 'react';

export default class GenCycleButton extends React.Component {
  // Props
  // states : [
  //    {button: GenButton,
  //     value: String,
  //     nextValue: String}]
  // disabled : Bool
  // currentValue : String
  // onClick (Value ->)

  currentStateObject = () => {
    currentValue = this.props.currentValue;
    maybeCurrentStateObject = this.props.states.filter(function(state){
      return(state.value === currentValue);
    })[0];

    if (maybeCurrentStateObject) {
      return(maybeCurrentStateObject);
    } else {
      return(this.props.states[0]);
    }
  };

  onClick = (value) => {
    currentStateObject = this.currentStateObject();
    this.props.onClick(currentStateObject.nextValue);
  };

  render() {
    currentStateObject = this.currentStateObject();
    return(
      <GenButton
        name={currentStateObject.button.name}
        highlighted={currentStateObject.button.highlighted}
        disabled={currentStateObject.button.disabled || this.props.disabled}
        onClick={this.onClick} />
    );
  }
}
