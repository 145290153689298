import * as React from 'react';

import * as classNames from 'classnames';

interface ChipProps {
  label: string
  onDelete?: (event: any) => void;
  tabIndex: number;
  'data-tag-index': number;
}

export default function AqChip(props: ChipProps): React.ReactElement {
  const {
    label,
    onDelete,
    tabIndex,
  } = props;

  return (
    <div className={classNames('AqChip')} tabIndex={tabIndex}>
      <span>{label}</span>
      {onDelete && (
        <div className={classNames('AqChip-delete')} onClick={onDelete}>
          <i className="fa fa-times"></i>
        </div>
      )}
    </div>
  );
}
