import React from 'react'
import GenList from 'Gen/List/component'
import DocumentSetDocumentRelationSummary from 'DocumentSetDocumentRelation/Summary/component'
import { documentListDefaults } from 'Document/List/defaults'

const caseHeader = (isIllnessScript, isStudentResource) => {
  if (isIllnessScript) {
    return "Condition"
  } else if (isStudentResource) {
    return "Student Resource"
  } else {
    return "Title"
  }
}

const TableHeader = (isIllnessScript, isStudentResource) => (
  <tr style={{height: '100%', padding: "0px"}} >
    <th style={{width: '120px', padding: "0px"}} ></th>
    <th style={{width: '100%', padding: "0px"}} ></th>
    {!isStudentResource && <th style={{width: '50%', padding: "0px"}} ></th>}
    {isIllnessScript && <th style={{width: '250px', padding: "0px"}} ></th>}
    {!isIllnessScript && <th style={{width: '50%', padding: "0px"}} ></th>}
  </tr>
)

const SortOptions = (isStudentResource, isIllnessScript, documentSetCirculation) => {
  let sortOptions = [
      {
        "title": "Title",
        "value": "documents.title"
      },
      {
        "title": "Date Updated",
        "value": "documents.last_release_date"
      },
    ]

  if (documentSetCirculation !== "exam_circulation") {
    sortOptions = sortOptions.concat([
      {
        "title": "Last Accessed",
        "value": "date_last_accessed"
      }
    ])
  }

  if (!isStudentResource && !isIllnessScript && documentSetCirculation !== "exam_circulation") {
    sortOptions = sortOptions.concat([
      {
        "title": "Case Completion",
        "value": "user_progress"
      }
    ])
  }

  return sortOptions
}

const DocumentSetDocumentRelationList = (props) => (
  <div className='document-set-document-relation-list'>
    <table>
      <GenList {...documentListDefaults}
        tableHeader={TableHeader(props.isIllnessScript, props.isStudentResource)}
        itemView={(document) => (
          <DocumentSetDocumentRelationSummary document={document} key={document.id} shouldHideProgress={props.isStudentResource} isIllnessScript={props.isIllnessScript}/>
        )}
        itemsURL={"/document_sets/" + props.document_set_id + "/document_set_document_relations"}
        scopedFilterOptions={props.scoped_filter_options}
        scopedFilterOptions2={props.scoped_filter_options2}
        sortOptions={SortOptions(props.isStudentResource, props.isIllnessScript, props.documentSetCirculation)}
      />
    </table>
  </div>
)

export default DocumentSetDocumentRelationList
