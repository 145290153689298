import React from 'react'
import ReactDOM from 'react-dom'

import { applyMiddleware, createStore, compose } from 'redux'
import { thunk } from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import promise from 'redux-promise-middleware'

import reducer from 'reducers/index'
import reportsSaga from 'sagas/reportsSaga'

const sagaMiddleware = createSagaMiddleware()

const middleware = applyMiddleware(
  thunk,
  // delayerMiddleware(),
  promise,
  sagaMiddleware,
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  const store = createStore(reducer, composeEnhancers(middleware))
  sagaMiddleware.run(reportsSaga)
  return store
}
