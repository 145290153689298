import { createSelector } from 'reselect'

export const CREATE = 'relier-frontend/report/CREATE'
export const CREATE_FAILED = 'relier-frontend/report/CREATE_FAILED'
export const CHECK_FAILED = 'relier-frontend/report/CHECK_FAILED'
export const CREATED = 'relier-frontend/report/CREATED'
export const DOWNLOAD = 'relier-frontend/report/DOWNLOAD'
export const DOWNLOAD_FAILED = 'relier-frontend/report/DOWNLOAD_FAILED'
export const RESET = 'relier-frontend/report/RESET'

export const reportsSelector = state => state.reports
export const isLoadingSelector = createSelector(reportsSelector, (r) => r.isLoading)
export const errorSelector = createSelector(reportsSelector, (r) => r.error)
export const urlSelector = createSelector(reportsSelector, (r) => r.url)
export const reportTypeSelector = createSelector(reportsSelector, (r) => r.reportType)

const initialState = {isLoading: false, error: false}

export default function reducer(state = initialState, {type, reportType, url}) {
  switch(type) {
    case RESET:
      return initialState
    case CREATE:
      return {...state,
        isLoading: true,
        reportType,
      }
    case CREATED:
      return {...state,
        error: false,
        isLoading: false,
        url,
      }
    default:
      return state
  }
}
