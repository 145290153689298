import React from 'react'
import { Provider } from 'react-redux'

import store from 'store/store'
import DocumentSetDocumentRelationList from 'components/DocumentSetDocumentRelation/List/component'

const DocumentSetDocumentRelationListApp = (props, _railsContext) => (
  () => (
    <Provider store={store()}>
      <DocumentSetDocumentRelationList {...props}/>
    </Provider>
  )
)

export default DocumentSetDocumentRelationListApp;
