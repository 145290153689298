import React from 'react'

export default function YesNoCircle(props) {
  const {
    isYes
  } = props

  if (isYes) {
    return <div className="progress_icon progress-done"></div>
  } else {
    return <div className="progress_icon progress-ban"></div>
  }
}
