import * as React from 'react';

import AqAutocompleteSelect from '../Aq/AutocompleteSelect'

import ReportsFilters from './Filters'

interface ReportSetting {
  title: string;
  reportOptionsUrl: string;
  createReportUrl: string;
}

interface ReportsMultiDashboardProps {
  reportSettings: Array<ReportSetting>;
}

export default function ReportsMultiDashboard(props: ReportsMultiDashboardProps) {
  const {
    reportSettings,
  } = props;

  const [activeReport, setActiveReport] = React.useState<{value: string, label: string}>();


  let renderedReportSetting
  if (activeReport) {
    const reportSetting = reportSettings.find((reportSetting) => reportSetting.reportOptionsUrl === activeReport.value);
    renderedReportSetting = <ReportsFilters {...reportSetting} />
  }

  return (
    <div className="panel" style={{minHeight: '1000px'}}>
      <label>Select Report</label>
      <AqAutocompleteSelect<{value: string, label: string}, false>
        options={reportSettings.map((report) => ({ value: report.reportOptionsUrl, label: report.title }))}
        value={activeReport || null}
        placeholder={"Select Report"}
        onChange={(event, newValue) => setActiveReport(newValue)}
      />
      {renderedReportSetting}
    </div>
  )
}
