import React from 'react'

import DocumentSummary from 'Summary/component'

export const documentListDefaults = {
  itemsURL:"/documents",
  itemView:function(document){
    return(<DocumentSummary document={document} key={document.id}/>);
  },
  isInfiniteScroll: true,
  search:true,
  defaultSortValue: 'documents.title',
  defaultSortDirection: 'ascending',
  sortOptions:[
                {
                  "title": "Title",
                  "value": "documents.title"
                },
                {
                  "title": "Date Updated",
                  "value": "documents.last_release_date"
                }
              ]
};
