const already_url = /^https?:\/\//

export function full_url(path){
  if (path.match(already_url)) {
    return path
  }
  // CAN NOT USE window.location.origin BECAUSE OF INTERNET EXPLORER
  return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '') + path
}

export function getUrlParameter(sParam){
    var sPageURL = window.location.search.substring(1)
    var sURLVariables = sPageURL.split('&')
    for (var i = 0; i < sURLVariables.length; i++)
    {
        var sParameterName = sURLVariables[i].split('=')
        if (sParameterName[0] == sParam)
        {
            return sParameterName[1]
        }
    }
}
