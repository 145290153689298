import React from 'react';

const ProgressBar = ({ percent }) => {
  const colors = {
    "very_certain": "first",
    "certain": "second",
    "uncertain": "third",
    "very_uncertain": "fourth"
  };

  const segments = Object.keys(colors).map((key) => ({
    width: percent[key],
    color: colors[key]
  }));

  return (
    <div className="progressBarContainer">
      <div className="progressBar">
        {segments.map((segment, index) => (
          <div
            key={index}
            className={`progressBarSegment ${segment.color}`}
            style={{ width: `${segment.width}%` }}
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
