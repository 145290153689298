import React from 'react'
import { connect } from 'react-redux'
import * as classNames from 'classnames'
import GenModal from 'Gen/Modal/component'
import DocumentDetail from 'Document/Detail/component'
import ActionButton from 'Document/ActionButton/component'
import { TOGGLE_MODAL, activeModalSelector, documentIdSelector } from 'reducers/ui/documentSummary'
import IntegrationsTags from "../../Integrations/component";

const searchHit = (content) => /search-hit/.test(content)

const sanitize = (content) => content && content.replace(/^"(.*)"$/, '$1')
const synopsisContentSelector = (doc) => sanitize(doc.case_synopsis)

const LearningObjectivesModal = ({ document, toggleModal }) =>
  <GenModal open >
    <div className="gen-modal-panel">
      <h1>Learning Objectives</h1>
      <b dangerouslySetInnerHTML={{__html: document.title}} />
      <hr/>
      <p>Upon completion of this module, the learner should be able to:</p>
      {
        document.learning_objectives.map((lo) => (
          <div class="doc-learning-objective" >
            <div class="learning-objective-arrow"></div>
            <div class="learning-objective-content">
              {lo.name}
            </div>
          </div>
        ))
      }
      <br/>
      <button onClick={toggleModal(this)}>Close</button>
    </div>
  </GenModal>

const CaseSynopsisModal = ({ document, toggleModal, enableIntegrations }) => {
  return(
    <GenModal open >
      <div className="gen-modal-panel">
        <h1>Synopsis</h1>
        <b dangerouslySetInnerHTML={{__html: document.title}} />
        <hr/>
        <div dangerouslySetInnerHTML={{__html: synopsisContentSelector(document)}} />
        {enableIntegrations &&
          <IntegrationsTags
            final_diagnoses={document.final_diagnoses }
            basic_science_disciplines={document.basic_science_disciplines}
            clinical_excellence={document.clinical_excellence}
          />
        }
        <button onClick={toggleModal(this)}>Close</button>
      </div>
    </GenModal>
  )}

const DocumentSummary = ({
  activeModal,
  documentId,
  document,
  toggleModal,
  caseSummaryURL,
  enableIntegrations,
  ...props
}) => {
  return (
    <DocumentDetail {...props} {...{document}}>
      {document.id === documentId && activeModal}
      <ul className="unstyled">
        <li>
          <ActionButton onClick={ onSynopsisClick(document, toggleModal, enableIntegrations) }
            icon="clipboard"
            className={classNames({ 'search-hit': searchHit(synopsisContentSelector(document))})}
          >
            Synopsis
          </ActionButton>
        </li>
        <li>
          <ActionButton onClick={toggleModal(<LearningObjectivesModal {...{document, toggleModal}}/>, document.id)}
            icon="check"
          >
            Learning Objectives
          </ActionButton>
        </li>
        <li>
          <ActionButton href={caseSummaryURL.replace(/:document_id/,document.id)} icon="file-pdf">
            Case Summary
            <i className="fa fa-external-link-alt external-link-icon"> </i>
          </ActionButton>
        </li>
      </ul>
    </DocumentDetail>
  )
}

const onSynopsisClick = (document, toggleModal, enableIntegrations) => {
  const toggleModalCallback = toggleModal(<CaseSynopsisModal {...{document, toggleModal, enableIntegrations}}/>, document.id)

  return (e) => {
    analytics.track("click_on_synopsis", {})
    toggleModalCallback(e)
  }
}

const mapStateToProps = (state) => ({
  activeModal: activeModalSelector(state),
  documentId: documentIdSelector(state)
})

const mapDispatchToProps = (dispatch, oProps) => ({
  toggleModal: (modal, documentId) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch({ type: TOGGLE_MODAL, modal, documentId })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSummary)
