import React from 'react'

export default function GenPercentageCircle(props) {
  const {
    percentage
  } = props

  if (percentage >= 100.0) {
    return <div className="progress_icon progress-done"></div>
  } else if (percentage <= 0.0) {
    return <div className="progress_icon progress-none"></div>
  } else{
    return <div className="progress_icon progress-percent">{Math.round(percentage)}<span>%</span></div>
  }

  return <div className="progress_icon progress-none"></div>
}
