import * as React from 'react';
import { useAutocomplete, UseAutocompleteProps, AutocompleteValue} from '@mui/base/useAutocomplete';

import AqChip from './Chip'

import * as classNames from 'classnames'


interface AqAutocompleteSelectProps<Value extends {value: string | number, label: string}, Multiple extends boolean> extends UseAutocompleteProps<Value, Multiple, false, false> {
  placeholder?: string;
}

export default function AqAutocompleteSelect<Value extends {value: string | number, label: string}, Multiple extends boolean>(props: AqAutocompleteSelectProps<Value, Multiple>) {
  const {
    placeholder,
  } = props;

  const {
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    // getClearProps,
    getListboxProps,
    getOptionProps,
    getTagProps,
    // dirty,
    id,
    popupOpen,
    focused,
    // anchorEl,
    // setAnchorEl,
    value,
    groupedOptions,
  } = useAutocomplete({
    ...props,
    componentName: 'AqAutocompleteSelect',
    isOptionEqualToValue: (option, value) => option.value === value.value,
  });

  const isMultipleWithSelection = Array.isArray(value) && value.length > 0

  return (
    <div className={'AqAutocompleteSelect__wrapper'}>
      <div
        {...getRootProps()}
        className={classNames('AqAutocompleteSelect__root', focused && 'focused')}
      >
        <div className={classNames('AqAutocompleteSelect__input-wrapper')}>
          {isMultipleWithSelection && (
            value.map((val, index) => {
              return (
                <AqChip
                  label={val.label}
                  {...getTagProps({index})}
                />
              )
            })
          )}
          <input
            id={id}
            {...getInputProps()}
            placeholder={!isMultipleWithSelection ? placeholder : ""}
            className={classNames('AqAutocompleteSelect__input')}
          />
        </div>
        <button
          {...getPopupIndicatorProps()}
          className={'Autocomplete__indicator'}
        >
          <i className="fa fa-chevron-down"></i>
        </button>
      </div>
      <div
        className={classNames('AqAutocompleteSelect__popup', popupOpen && 'open')}
      >
        <ul
          {...getListboxProps()}
          className="AqAutocompleteSelect__listbox"
        >
          {(groupedOptions as Value[]).map((option, index) => {
            const optionProps = getOptionProps({ option, index });

            return (
              <li {...optionProps} className="AqAutocompleteSelect__option">
                {option.label}
              </li>
            );
          })}

          {groupedOptions.length === 0 && (
            <li className="AqAutocompleteSelect__no-options">No results</li>
          )}
        </ul>
      </div>
    </div>
  )
}
