import { createSelector } from 'reselect'

export const TOGGLE_MODAL = 'relier-frontend/ui/documentSummary/TOGGLE_MODAL'

export const documentSummarySelector = state => state.ui.documentSummary
export const activeModalSelector = createSelector(documentSummarySelector, (r) => r.activeModal)
export const documentIdSelector = createSelector(documentSummarySelector, (r) => r.documentId)

export default function reducer(state = {}, {type, modal, documentId}) {
  switch (type) {
    case TOGGLE_MODAL:
      return {...state,
        activeModal: state.activeModal === modal ? null : modal,
        documentId: state.activeModal === modal ? null : documentId
      }
    default:
      return state
  }
}
