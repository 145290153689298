import React from 'react'

import GenList from 'Gen/List/component'
import GroupSummary from 'Summary/component'

export default function GroupList(props) {
  var domain = props.domain;
  return(
      <GenList
        itemView={function(group){
          return(<GroupSummary group={group} key={group.id} domain={domain}/>);
        }}
        itemsURL={"/groups"}
        isInfiniteScroll={true}
        search={true}
        sortOptions={[
                      {
                        "title": "Name",
                        "value": "groups.name"
                      }
                    ]}
      />
  );
}
