import React from 'react'

import GenButton from 'Gen/Button/component'

export default class GenActionButton extends React.Component {
  static defaultProps = {
    type: "GET",
    // dataURL: "",
    data: {},
    // onSuccess: (),
    onError: () => null
  };

  constructor(props, context) {
    super(props, context);
    var defaults = {
      isLoading: false,
    };

    this.state = defaults
  }

  handleSuccess(data, textStatus, jqXHR) {
    this.setState({isLoading: false})
    this.props.onSuccess(data, textStatus, jqXHR)
  }

  handleError(xhr, status, error) {
    this.setState({isLoading: false})
    this.props.onError(xhr, status, error)
  }

  render() {
    return <GenButton
      {...this.props}
      loading={this.state.isLoading}
      onClick={() => {
        let doIt = true
        if (this.props.confirmText) {
          doIt = confirm(this.props.confirmText)
        }

        if (doIt === true ) {
          this.setState({isLoading: true})

          $.ajax({
            type: this.props.type,
            url: this.props.dataURL,
            dataType: 'json',
            data: this.props.type === "GET" ? this.props.data : JSON.stringify(this.props.data),
            contentType: 'application/json',
            success: this.handleSuccess.bind(this),
            error: this.handleError.bind(this)
          })
        }
      }}
    />
  }

}
