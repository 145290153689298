import React from 'react'

import GenPostButton from 'Gen/PostButton/component'

import {formatAsPrice} from 'helpers/number_format'

const discountStyle = {width: "15px", float: "left", position: "sticky", top: "15%", left: "15px"}
const currencyBoxStyle = {overflow: "hidden", width: "150px"}
const discountBoxStyle = {overflow: "hidden", width: "150px", paddingLeft: "13px"}


export default class QuoteInterfaceQuoteNextYear extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      add_price_selected: !!props.quote.add_price,
      fm_price_selected: !!props.quote.fm_price,
      ger_price_selected: !!props.quote.ger_price,
      im_price_selected: !!props.quote.im_price,
      peds_price_selected: !!props.quote.peds_price,
      rad_price_selected: !!props.quote.rad_price,
      iis_price_selected: !!props.quote.iis_price,
      cx_price_selected: !!props.quote.cx_price,
      neu_price_selected: !!props.quote.neu_price,
      obgyn_price_selected: !!props.quote.obgyn_price,
      woc_price_selected: !!props.quote.woc_price,
      wmd_price_selected: !!props.quote.wmd_price,
      careSubtotal: props.quote.care_subtotal,
      signatureSubtotal: props.quote.signature_subtotal,
      wiseSubtotal: props.quote.wise_subtotal,
      signatureGrant: props.quote.signature_grant || 0,
      wiseGrant: props.quote.wise_grant || 0,
      prorate: props.quote.prorate,
      invoiceStartDate: props.quote.invoice_start_date,
      invoiceEndDate: props.quote.invoice_end_date,
      subscriptionStartDate: props.quote.subscription_start_date,
      subscriptionEndDate: props.quote.subscription_end_date,
      memo: props.quoteInterface.memo,
      beforeCPCheckboxSelected: {
        rad_price_selected: !!props.quote.rad_price,
        fm_price_selected: !!props.quote.fm_price,
        ger_price_selected: !!props.quote.ger_price,
        im_price_selected: !!props.quote.im_price,
        peds_price_selected: !!props.quote.peds_price,
        iis_price_selected: !!props.quote.iis_price,
        cx_price_selected: !!props.quote.cx_price,
        neu_price_selected: !!props.quote.neu_price,
        obgyn_price_selected: !!props.quote.obgyn_price,
      },
      cpCheckboxSelected: [ props.quote.fm_price,
                            props.quote.ger_price,
                            props.quote.im_price,
                            props.quote.peds_price,
                            props.quote.rad_price,
                            props.quote.iis_price,
                            props.quote.cx_price,
                            props.quote.neu_price,
                            props.quote.obgyn_price,
                          ].filter(x => x === 1).length >= 5,
      cxHover: false,
      calibrateHover: false,
    }
  }

  handleCXMouseIn() {
    this.setState({ cxHover: true })
  }

  handleCXMouseOut() {
    this.setState({ cxHover: false })
  }

  handleCalibrateMouseIn() {
    this.setState({ calibrateHover: true })
  }

  handleCalibrateMouseOut() {
    this.setState({ calibrateHover: false })
  }

  lastSchoolYearDisplay() {
    return `20${this.props.schoolYearStart - 1}-${this.props.schoolYearStart}`
  }

  schoolYearDisplay() {
    return `20${this.props.schoolYearStart}-${this.props.schoolYearStart + 1}`
  }

  schoolYearStartFull() {
    return 2000 + this.props.schoolYearStart
  }

  schoolYearEndFull() {
    return 2000 +  this.props.schoolYearStart + 1
  }

  startOfMonthFromIndex(index) {
    const schoolYearStartFull = this.schoolYearStartFull()
    const schoolYearEndFull = this.schoolYearEndFull()

    return {
      0:`July 1, ${schoolYearStartFull}`,
      1:`August 1, ${schoolYearStartFull}`,
      2:`September 1, ${schoolYearStartFull}`,
      3:`October 1, ${schoolYearStartFull}`,
      4:`November 1, ${schoolYearStartFull}`,
      5:`December 1, ${schoolYearStartFull}`,
      6:`January 1, ${schoolYearEndFull}`,
      7:`February 1, ${schoolYearEndFull}`,
      8:`March 1, ${schoolYearEndFull}`,
      9:`April 1, ${schoolYearEndFull}`,
      10:`May 1, ${schoolYearEndFull}`,
      11:`June 1, ${schoolYearEndFull}`
    }[index]
  }

  endOfMonthFromIndex(index) {
    const schoolYearStartFull = this.schoolYearStartFull()
    const schoolYearEndFull = this.schoolYearEndFull()

    return {
      0:`July 31, ${schoolYearStartFull}`,
      1:`August 31, ${schoolYearStartFull}`,
      2:`September 30, ${schoolYearStartFull}`,
      3:`October 31, ${schoolYearStartFull}`,
      4:`November 30, ${schoolYearStartFull}`,
      5:`December 31, ${schoolYearStartFull}`,
      6:`January 31, ${schoolYearEndFull}`,
      7:`February 28, ${schoolYearEndFull}`,
      8:`March 31, ${schoolYearEndFull}`,
      9:`April 30, ${schoolYearEndFull}`,
      10:`May 31, ${schoolYearEndFull}`,
      11:`June 30, ${schoolYearEndFull}`
    }[index]
  }

  updateCheckbox(checkboxName,courseSetName){
    let requiredSignatureCourse = 4
    if(this.props.isAddendum && this.props.schoolYearStart >= 23){
      Object.keys(this.props.signatureCourseStatuses).forEach((course) => {
        if(this.props.signatureCourseStatuses[course]["confirmed"]) {
          requiredSignatureCourse = requiredSignatureCourse-1
        }
      })
    }
    if(courseSetName==="Aquifer" && !this.state[checkboxName] &&
      ([  this.state.rad_price_selected,
          this.state.fm_price_selected,
          this.state.ger_price_selected,
          this.state.im_price_selected,
          this.state.peds_price_selected,
          this.state.cx_price_selected,
          this.state.iis_price_selected,
          this.state.neu_price_selected,
          this.state.obgyn_price_selected
        ].filter(x => x).length===requiredSignatureCourse)){
          this.handleCPCheckbox(true)
    }else{
      this.setState({[checkboxName]: !this.state[checkboxName]})
    }
  }

  handleCPCheckbox(isChecked){
    if(isChecked){
      let paidCourses = []
      if(this.props.isAddendum){
        paidCourses = Object.keys(this.props.signatureCourseStatuses).map((course) => {
                            if (this.props.signatureCourseStatuses[course]["confirmed"]) {
                              return this.props.signatureCourseStatuses[course]["key"]
                            }
                          }).filter(element => element!==undefined)
      }

      this.setState({
        beforeCPCheckboxSelected: {
          rad_price_selected: (paidCourses.length>0 && paidCourses.includes("rad_price")) ? false : this.state.rad_price_selected,
          fm_price_selected: (paidCourses.length>0 && paidCourses.includes("fm_price")) ? false : this.state.fm_price_selected,
          ger_price_selected: (paidCourses.length>0 && paidCourses.includes("ger_price")) ? false : this.state.ger_price_selected,
          im_price_selected: (paidCourses.length>0 && paidCourses.includes("im_price")) ? false : this.state.im_price_selected,
          peds_price_selected: (paidCourses.length>0 && paidCourses.includes("peds_price")) ? false : this.state.peds_price_selected,
          cx_price_selected: (paidCourses.length>0 && paidCourses.includes("cx_price")) ? false : this.state.cx_price_selected,
          iis_price_selected: (paidCourses.length>0 && paidCourses.includes("iis_price")) ? false : this.state.iis_price_selected,
          neu_price_selected: (paidCourses.length>0 && paidCourses.includes("neu_price")) ? false : this.state.neu_price_selected,
          obgyn_price_selected: (paidCourses.length>0 && paidCourses.includes("obgyn_price")) ? false : this.state.obgyn_price_selected
        },
        fm_price_selected: (paidCourses.length>0 && paidCourses.includes("fm_price")) ? false : true,
        ger_price_selected: (paidCourses.length>0 && paidCourses.includes("ger_price")) ? false : true,
        im_price_selected: (paidCourses.length>0 && paidCourses.includes("im_price")) ? false : true,
        peds_price_selected: (paidCourses.length>0 && paidCourses.includes("peds_price")) ? false : true,
        rad_price_selected: (paidCourses.length>0 && paidCourses.includes("rad_price")) ? false : true,
        cx_price_selected: (paidCourses.length>0 && paidCourses.includes("cx_price")) ? false : true,
        iis_price_selected: (paidCourses.length>0 && paidCourses.includes("iis_price")) ? false : true,
        neu_price_selected: (paidCourses.length>0 && paidCourses.includes("neu_price")) ? false : true,
        obgyn_price_selected: (paidCourses.length>0 && paidCourses.includes("obgyn_price")) ? false : true,
        cpCheckboxSelected: true
      })
    }else{
      this.setState({
        ...this.state.beforeCPCheckboxSelected,
        cpCheckboxSelected: false
      })
    }
  }

  handleProrateCheckbox() {
    if (!this.state.prorate) {
      this.setState({ prorate: !this.state.prorate })
    } else {
      this.setState({
        prorate: !this.state.prorate,
        invoiceStartDate: null,
        invoiceEndDate: null,
        subscriptionStartDate: null,
        subscriptionEndDate: null
      })
    }
  }

  onSelectChangedISD(e) {
    let newStartDate = parseInt(e.target.value)
    if (this.state.invoiceEndDate && (parseInt(e.target.value) > this.state.invoiceEndDate)) {
      this.setState({ invoiceStartDate: newStartDate, invoiceEndDate: newStartDate + 1 })
    } else {
      this.setState({ invoiceStartDate: newStartDate})
    }
  }

  onSelectChangedIED(e) {
    this.setState({ invoiceEndDate: parseInt(e.target.value) })
  }

  onSelectChangedSSD(e) {
    let newStartDate = parseInt(e.target.value)
    if (this.state.subscriptionEndDate && (newStartDate > this.state.subscriptionEndDate)) {
      this.setState({ subscriptionStartDate: newStartDate, subscriptionEndDate: newStartDate + 1 })
    } else {
      this.setState({ subscriptionStartDate: newStartDate })
    }
  }

  onSelectChangedSED(e) {
    this.setState({ subscriptionEndDate: parseInt(e.target.value) })
  }

  dateDropDown(handlerFunc, value, defaultName, isEnd=false, cutOff=0) {
    return <div style={{display: "inline-block"}}>
      <select onChange={handlerFunc} value={value}>
        <option value="default" selected disabled hidden>{defaultName}</option>
        {[...Array(12).keys()].map((i) => {
          return <option value={i+1} disabled={parseInt(cutOff) > i+1}>{isEnd ? this.endOfMonthFromIndex(i) : this.startOfMonthFromIndex(i)}</option>
        })}
      </select>
    </div>
  }

  handleExceptionPriceChange(priceKey) {
    return (e) => {
      let newPrice = parseInt(e.target.value) || 0
      this.setState({[priceKey]: newPrice})
    }
  }

  handleOnClick(e) { e.stopPropagation() }
  handleMemoChange(e) { this.setState({memo: e.target.value}) }

  dateSelector() {
    let dateSelector
    if (this.props.is_admin) {
      dateSelector = [
        <h6 key='course-dates-intro'>Course dates are outside of normal subscription period:</h6>,
        <input
          key='course-dates-checkbox'
          name="customDateRange"
          type="checkbox"
          onChange={this.handleProrateCheckbox.bind(this)}
          checked={this.state.prorate}
          disabled={this.props.quote.quote_confirmed_at}
        />
      ]

      if (this.state.prorate) {
        dateSelector = dateSelector.concat([
          <p key='course-dates-invoice-par'>Invoice Date Range</p>,
          this.dateDropDown(this.onSelectChangedISD.bind(this), this.state.invoiceStartDate, "Invoice Start Date"),
          this.state.invoiceStartDate && this.dateDropDown(this.onSelectChangedIED.bind(this), this.state.invoiceEndDate, "Invoice End Date", true, this.state.invoiceStartDate),
          <p key='course-dates-subscription-par'>Subscription Date Range</p>,
          this.dateDropDown(this.onSelectChangedSSD.bind(this), this.state.subscriptionStartDate, "Subscription Start Date"),
          this.state.subscriptionStartDate && this.dateDropDown(this.onSelectChangedSED.bind(this), this.state.subscriptionEndDate, "Subscription End Date", true, this.state.subscriptionStartDate),
        ])
      }
    } else if (this.state.invoiceStartDate && this.state.invoiceEndDate) {
      dateSelector = [
        <h6>Invoice Date Range</h6>,
        <p>{this.startOfMonthFromIndex(this.state.invoiceStartDate - 1)} - {this.endOfMonthFromIndex(this.state.invoiceEndDate - 1)}</p>
      ]

      if (this.state.subscriptionStartDate && this.state.subscriptionEndDate) {
        dateSelector.concat([
          <h6>Subscription Date Range</h6>,
          <p>{this.startOfMonthFromIndex(this.state.subscriptionStartDate - 1)} - {this.endOfMonthFromIndex(this.state.subscriptionEndDate - 1)}</p>
        ])
      }
    }

    return dateSelector
  }

  memo() {
    let memo
    if (this.props.is_admin) {
      memo = [
        <h6 key='memo-title'>Additional Notes:</h6>,
        <textarea key='memo-input' style={{width: "100%"}} type="text" name="business_development_managerComment" onChange={this.handleMemoChange.bind(this)} onClick={this.handleOnClick.bind(this)} defaultValue={this.state.memo || ''} />
      ]
    } else if (this.props.quoteInterface.memo && this.props.quoteInterface.memo.length > 0) {
      memo = [
        <h6 key='memo-title'>Additional Notes:</h6>,
        <p key='memo-text'>{this.props.quoteInterface.memo}</p>
      ]
    }

    return memo
  }

  dateDisplay() {
    if (this.state.prorate) {
      if (this.state.invoiceStartDate && this.state.invoiceEndDate) {
        return this.startOfMonthFromIndex(this.state.invoiceStartDate - 1) + ' - ' + this.endOfMonthFromIndex(this.state.invoiceEndDate - 1)
      } else {
        return "Custom "
      }
    } else {
      return `July 1, ${this.schoolYearStartFull()} - June 30, ${this.schoolYearEndFull()}`
    }
  }

  render() {
    const {
      quoteInterface,
      quote,
      is_admin,
      addictionCourseStatuses,
      signatureCourseStatuses,
      wiseCourseStatuses,
      freeCourseStatuses,
      priceLookup,
      isAddendum,
      programName,
      addictionCourseNames,
      paidSignatureCourseNames,
      wiseCourseNames,
      freeCourseNames
    } = this.props

    const {
      memo,
      prorate,
      invoiceStartDate,
      invoiceEndDate,
      subscriptionStartDate,
      subscriptionEndDate,
      signatureGrant,
      wiseGrant,
    } = this.state

    const editUrl = window.location.href
    const downloadPDFUrl = editUrl.replace("edit", "pdf_download.pdf")
    const updateQuoteUrl = editUrl.replace("edit", "update")
    const cxTooltipStyle = {
      display: this.state.cxHover ? 'inline' : 'none',
      position: "absolute",
      width: "400px",
      height: "175px",
      border: "1px solid black",
      background: "rgb(186, 236, 255)",
      paddingLeft: "5px"
    }
    const calibrateTooltipStyle = {
      display: this.state.calibrateHover ? 'inline' : 'none',
      position: "absolute",
      width: "400px",
      height: "125px",
      border: "1px solid black",
      background: "rgb(186, 236, 255)",
      paddingLeft: "5px"
    }

    let isExceptionPriceCategory = quoteInterface.price_category === 3
    let isCheckboxEnabled = (is_admin && !quote.quote_confirmed_at) || (!quote.quote_submitted_at && !isExceptionPriceCategory && !prorate)

    let prorateFormula = 1
    let shouldUseProrateFormula = invoiceStartDate && invoiceEndDate && !isExceptionPriceCategory
    if (shouldUseProrateFormula) {
      const subscriptionMonths = (invoiceEndDate - invoiceStartDate + 1)
      prorateFormula = subscriptionMonths / 12
    }

    let courseSets = [{
      setName: "Aquifer",
      priceLookupName: "Signature",
      subtotalLookupName: "signature",
      courseNames: paidSignatureCourseNames,
      courseStatuses: signatureCourseStatuses,
    },{
      setName: "WISE",
      priceLookupName: "Wise",
      subtotalLookupName: "wise",
      courseNames: wiseCourseNames,
      courseStatuses: wiseCourseStatuses,
    },{
      setName: "CARE",
      priceLookupName: "Care",
      subtotalLookupName: "care",
      courseNames: addictionCourseNames,
      courseStatuses: addictionCourseStatuses,
    }]

    let courseSubtotalsForButton = {}

    let courseSetSummaries = courseSets.map(courseSet => {
      let courseSummaries = courseSet.courseNames.map(courseName => {
        let courseDetails = courseSet.courseStatuses[courseName] || {}
        let courseKey = courseDetails.key

        let isSelected = this.state[`${courseKey}_selected`]
        let alreadyQuotedThisYear = courseDetails.confirmed

        return {
          courseName: courseName,
          courseKey: courseKey,
          hadPreviously: courseDetails.current,
          alreadyQuotedThisYear: alreadyQuotedThisYear,
          isSelected: isSelected
        }
      })

      let subtotalKey = `${courseSet.subtotalLookupName}Subtotal`

      let courseSubtotal

      let maxCourses = 5
      if (courseSet.setName === "WISE") {
        maxCourses = 2
      } else if (courseSet.setName === "CARE"){
        maxCourses = 1
      }
      if (!isExceptionPriceCategory) {
        const courseSelected = courseSummaries.filter(summary => summary.isSelected)
        const courseCounter = courseSelected.length
        const courseAlreadyQuotedCount = courseSummaries.filter(summary => summary.alreadyQuotedThisYear).length
        const priceLookupByCount = priceLookup[courseSet.priceLookupName]
        const alreadyCountedSubtotal = priceLookupByCount[Math.min(courseAlreadyQuotedCount, maxCourses)]
        const allCountedSubotal = priceLookupByCount[Math.min((courseAlreadyQuotedCount + courseCounter), maxCourses)]
        if (quote.quote_confirmed_at && !this.props.isAddendum) {
          courseSubtotal = Math.round(alreadyCountedSubtotal * prorateFormula)
        } else {
          courseSubtotal = Math.round((allCountedSubotal - alreadyCountedSubtotal) * prorateFormula)
        }
      } else {
        courseSubtotal = this.state[subtotalKey] || 0
      }

      courseSubtotalsForButton[`${courseSet.subtotalLookupName}_subtotal`] = courseSubtotal

      return {
        setName: courseSet.setName,
        courseSummaries: courseSummaries,
        subtotal: courseSubtotal,
        subtotalKey: subtotalKey,
      }
    })

    let courseTables = courseSetSummaries.map(courseSetSummary => {
      let paidCourseRows = courseSetSummary.courseSummaries.map(courseSummary => {
        if(this.props.schoolYearStart < 23 && (courseSummary.courseName === "Aquifer Clinical Excellence" || courseSummary.courseName === "Aquifer Neurology" || courseSummary.courseName === "Aquifer Sciences Integrated Illness Scripts")) {
          return null;
        }
        let cxTooltip
        let inlineStyle = {display: "inline"}
        if (courseSummary.courseName === "Aquifer Clinical Excellence") {
          cxTooltip =   <div style={inlineStyle}>
                          <div style={inlineStyle} onMouseOver={this.handleCXMouseIn.bind(this)} onMouseOut={this.handleCXMouseOut.bind(this)}><i className="fas fa-info-circle text-info" data-toggle="popover" style={{fontSize: "75%", verticalAlign: "top"}}/></div>
                          <div style={inlineStyle}>
                            <div style={cxTooltipStyle}>This collection of courses includes: <br/>Diagnostic Excellence<br/>High Value Care<br/>Intro to Telemedicine<br/>Palliative Care<br/>Social Determinants of Health<br/>Trauma Informed Care</div>
                          </div>
                        </div>
        }
        return <tr key={courseSummary.courseName}>
          <td style={{backgroundColor: 'white'}}>{courseSummary.courseName} {cxTooltip}</td>
          <td style={{backgroundColor: 'white', textAlign: "center"}}>
            <input
              type="checkbox"
              disabled={!isCheckboxEnabled}
              checked={isAddendum ? courseSummary.alreadyQuotedThisYear : courseSummary.hadPreviously}
              readOnly
            />
          </td>
          <td style={{backgroundColor: '#BAECFF', textAlign: "center"}}>
            <input
              id={`${courseSummary.courseKey}_selected`}
              type="checkbox"
              disabled={!isCheckboxEnabled || courseSummary.alreadyQuotedThisYear || (courseSetSummary.setName==="Aquifer" && this.state.cpCheckboxSelected)}
              onChange={isCheckboxEnabled && !courseSummary.alreadyQuotedThisYear && (() => this.updateCheckbox(`${courseSummary.courseKey}_selected`,courseSetSummary.setName))}
              checked={courseSummary.isSelected || (courseSetSummary.setName==="Aquifer" && this.state.cpCheckboxSelected)}
            />
          </td>
          <td style={{backgroundColor: 'white'}}></td>
        </tr>
      })
      if (courseSetSummary.setName === "Aquifer" && this.props.schoolYearStart >= 23) {
        let leftCheck = courseSetSummaries[0].courseSummaries.filter(summary =>
          (summary.courseName === "Aquifer Family Medicine" ||
          summary.courseName === "Aquifer Internal Medicine" ||
          summary.courseName === "Aquifer Pediatrics" ||
          summary.courseName === "Aquifer Neurology" ||
          summary.courseName === "Aquifer Radiology")).length >= 1
        let rightCheck = !!this.state.fm_price_selected || !!this.state.im_price_selected || !!this.state.peds_price_selected || !!this.state.rad_price_selected || !!this.state.neu_price_selected || !!this.state.obgyn_price_selected
        if (this.props.isAddendum) {
          leftCheck = courseSetSummaries[0].courseSummaries.filter(summary => summary.alreadyQuotedThisYear &&
            (summary.courseName === "Aquifer Family Medicine" ||
            summary.courseName === "Aquifer Internal Medicine" ||
            summary.courseName === "Aquifer Pediatrics" ||
            summary.courseName === "Aquifer Neurology" ||
            summary.courseName === "Aquifer Radiology")).length >= 1
          rightCheck = (courseSetSummaries[0].courseSummaries.filter(summary => summary.alreadyQuotedThisYear &&
            (summary.courseName === "Aquifer Family Medicine" ||
            summary.courseName === "Aquifer Internal Medicine" ||
            summary.courseName === "Aquifer Pediatrics" ||
            summary.courseName === "Aquifer Neurology" ||
            summary.courseName === "Aquifer Radiology")).length >= 1) ||
            (!!this.state.fm_price_selected || !!this.state.im_price_selected || !!this.state.peds_price_selected || !!this.state.rad_price_selected || !!this.state.neu_price_selected || !!this.state.obgyn_price_selected)
        }
        let inlineStyle = {display: "inline"}
        let calibrateTooltip =  <div style={inlineStyle}>
                                <div style={inlineStyle} onMouseOver={this.handleCalibrateMouseIn.bind(this)} onMouseOut={this.handleCalibrateMouseOut.bind(this)}><i className="fas fa-info-circle text-info" data-toggle="popover" style={{fontSize: "75%", verticalAlign: "top"}}/></div>
                                <div style={inlineStyle}>
                                  <div style={calibrateTooltipStyle}>When you subscribe to Aquifer Family Medicine, Aquifer Internal Medicine, Aquifer Pediatrics, Aquifer Radiology or Aquifer Neurology, you will automatically get access to the associated Aquifer Calibrate Formative Assessment.</div>
                                </div>
                              </div>

        let calibrateRow = <tr>
          <td>Aquifer Calibrate Formative Assessment {calibrateTooltip}</td>
          <td style={{backgroundColor: 'white', textAlign: "center"}}>
            <input
              hidden={true}
              type="checkbox"
              disabled
              checked={leftCheck}
            />
          </td>
          <td style={{backgroundColor: '#BAECFF', textAlign: "center"}}>
            <input
              type="checkbox"
              disabled
              onClick={isCheckboxEnabled && (() => this.setState({[`${courseSummary.courseKey}_selected`]: !this.state[`${courseSummary.courseKey}_selected`]}))}
              checked={rightCheck}
            />
          </td>
        </tr>
        paidCourseRows.push(calibrateRow)
      }

      let cpCheckbox
      let adjustedFreeCourseNames = freeCourseNames
      let freeCourseRows
      if (courseSetSummary.setName === "Aquifer") {
        if(this.props.schoolYearStart > 22) {
          cpCheckbox = <tr key="cpCheckbox">
            <td style={{backgroundColor: 'white'}}>Curricular Partner Program</td>
            <td style={{backgroundColor: 'white', textAlign: "center"}}></td>
            <td style={{backgroundColor: '#BAECFF', textAlign: "center"}}>
              <input
                id="cpCheckbox"
                type="checkbox"
                onChange={(event)=>{this.handleCPCheckbox(event.target.checked)}}
                checked={this.state.cpCheckboxSelected}
              />
            </td>
            <td style={{backgroundColor: 'white'}}></td>
          </tr>

          adjustedFreeCourseNames = freeCourseNames.filter(courseName=>courseName == "Aquifer Oral Presentation Skills")
        }

        freeCourseRows = adjustedFreeCourseNames.map((courseName) => {
          let courseDetails = freeCourseStatuses[courseName]

          return <tr key={courseName}>
            <td style={{backgroundColor: 'white'}}>{courseName}</td>
            <td style={{backgroundColor: 'white', textAlign: "center"}}>
              <input
                type="checkbox"
                disabled={!isCheckboxEnabled}
                checked={isAddendum || courseDetails.current}
                readOnly
              />
            </td>
            <td style={{backgroundColor: '#BAECFF', textAlign: "center"}}>
              <input
                name="selected"
                type="checkbox"
                disabled={!isCheckboxEnabled}
                checked
                readOnly
              />
            </td>
            <td style={{backgroundColor: 'white'}}>Free with your subscription</td>
          </tr>
        })
      }

      let subtotalViewCell
      if (is_admin && isExceptionPriceCategory) {
        if (quoteInterface.shared_success) {
          subtotalViewCell = <th><div style={{overflow: "hidden"}}><div style={discountStyle}>$</div><input style={{ overflow: "hidden", width: "150px", paddingLeft: "13px", border: '1px solid red' }} type="text" name="subtotal" onChange={this.handleExceptionPriceChange(courseSetSummary.subtotalKey)} value={courseSetSummary.subtotal} />Reminder: Shared Success Trial participating program!</div></th>
        } else {
          subtotalViewCell = <th><div style={{overflow: "hidden"}}><div style={discountStyle}>$</div><input style={discountBoxStyle} type="text" name="subtotal" onChange={this.handleExceptionPriceChange(courseSetSummary.subtotalKey)} value={courseSetSummary.subtotal} /></div></th>
        } 
      } else {
        subtotalViewCell = <th>${courseSetSummary.subtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</th>
      }

      return <table key={courseSetSummary.setName} style={{marginBottom: '25px'}}>
        <tbody>
          <tr key='header-row'>
            <th style={{width: "40%"}} > Course Name </th>
            <th style={{width: "15%"}} > {isAddendum ? this.schoolYearDisplay() : this.lastSchoolYearDisplay()} Subscriptions </th>
            <th style={{width: "15%"}} > {this.schoolYearDisplay()} Subscriptions </th>
            <th style={{width: "30%"}} >  </th>
            <th></th>
          </tr>
          {cpCheckbox}
          {paidCourseRows}
          {freeCourseRows}
          <tr key='footer-row'>
            <th>{courseSetSummary.setName} Annual Subscription Cost</th>
            <th></th>
            {subtotalViewCell}
            <th></th>
            <th></th>
          </tr>
        </tbody>
      </table>
    })

    let subtotal = courseSetSummaries.reduce((acc, curr) => acc + curr.subtotal, 0)

    let signatureGrantView
    let wiseGrantView
    if (is_admin) {
      signatureGrantView = <div style={{overflow: "hidden"}}><div style={discountStyle}>-$</div><input style={discountBoxStyle} type="text" name="signatureGrant" onChange={this.handleExceptionPriceChange('signatureGrant')} value={signatureGrant} /></div>
      wiseGrantView = <div style={{overflow: "hidden"}}><div style={discountStyle}>-$</div><input style={discountBoxStyle} type="text" name="wiseGrant" onChange={this.handleExceptionPriceChange('wiseGrant')} value={wiseGrant} /></div>
    } else {
      signatureGrantView = signatureGrant > 0 && <div style={{paddingLeft: '16px'}}>-{formatAsPrice(signatureGrant)}</div>
      wiseGrantView = wiseGrant > 0 && <div style={{paddingLeft: '16px'}}>-{formatAsPrice(wiseGrant)}</div>
    }

    let total = subtotal - signatureGrant - wiseGrant

    let buttonData = {
      quote: {
        ...courseSubtotalsForButton,
        signature_grant: signatureGrant,
        wise_grant: wiseGrant,
        subtotal: subtotal,
        total: total,
        prorate: prorate,
        invoice_start_date: invoiceStartDate,
        invoice_end_date: invoiceEndDate,
        subscription_start_date: subscriptionStartDate,
        subscription_end_date: subscriptionEndDate,
      },
      memo: memo,
    }

    courseSetSummaries.forEach(courseSetSummary => {
      courseSetSummary.courseSummaries.forEach(courseSummary => {
        buttonData.quote[courseSummary.courseKey] = courseSummary.isSelected ? 1 : null
      })
    })

    let renewalStage
    if (quote.quote_confirmed_at) {
      renewalStage = 'Quote Confirmed'
    } else if (quote.quote_submitted_at) {
      renewalStage = 'Ready to Confirm'
    } else if (quote.quote_sent_at) {
      renewalStage = 'Quote Sent'
    } else {
      renewalStage = 'Ready to Send'
    }

    let button
    if (renewalStage === 'Quote Sent') {
      button = <GenPostButton
        key="submitRenewal"
        name="Submit My Order"
        highlighted
        url={updateQuoteUrl}
        data={{
          saveType: 'SUBMIT',
          ...buttonData
        }}
      />
    } else if (renewalStage === 'Ready to Confirm') {
      if (is_admin) {
        button = <GenPostButton
          key="confirmRenewal"
          name="Confirm Quote"
          highlighted
          url={updateQuoteUrl}
          data={{
            saveType: 'CONFIRM',
            ...buttonData
          }}
        />
      } else {
        button = <GenPostButton
          key="submittedRenewal"
          name="Order Submitted"
          disabled
        />
      }
    } else if (renewalStage === 'Quote Confirmed') {
      button = <GenPostButton
        key="confirmedRenewal"
        name="Order Confirmed"
        disabled
      />
    }

    return <div className="document-set-user-permissions-select-list">
      <div className="submit-panel">
        <h1>Aquifer Quote</h1><br/><br/><br/>
        <h2 style={{color: "#0095c9"}}>{programName}</h2>
        <h3 style={{fontSize: "25px"}}>{this.dateDisplay()} Subscriptions</h3>
        <hr/>
        <table>
          <tbody>
            <tr>
              <td><b>Program Service Administrator</b><br/>{quoteInterface.psa_name}<br/>{quoteInterface.psa_email}</td>
              <td><b>Billing Contact</b><br/>{quoteInterface.billing_contact_name}<br/>{quoteInterface.billing_contact_email}</td>
              <td><b>Aquifer Relationship Manager</b><br/>{quoteInterface.business_development_manager}<br/>{quoteInterface.business_development_manager_email}</td>
            </tr>
          </tbody>
        </table>

        <p>By completing this form you are indicating which Aquifer courses your program wishes to subscribe to for the {this.schoolYearEndFull()} subscription year which runs from {this.dateDisplay()}.</p>
        <p>Once you press the 'Submit My Order' button your selections will be submitted to your Aquifer Relationship Manager for review.  After your submission has been reviewed you will receive an email confirming your order with a final .pdf of your confirmed quote, and an invoice will be sent to you within 5 business days.</p>
        <p>If you have any questions or have submitted your order in error please see our Subscription FAQs or email your Aquifer Relationship Manager at the address above.</p>

        {this.dateSelector()}
        {this.memo()}
      </div>
      <div className="submit-panel">
      <div>
        {courseTables}
      </div>
        <table style={{marginTop: '-10px'}}>
          <tbody>
            <tr style={{visibility: 'collapse'}}>
              <th style={{width: "30%"}} ></th>
              <th style={{width: "25%"}} ></th>
              <th style={{width: "15%"}} ></th>
              <th style={{width: "30%"}} ></th>
            </tr>
            {(wiseGrant > 0 || signatureGrant > 0) && <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}><b>SubTotal</b></td>
              <td style={{backgroundColor: '#BAECFF'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${(subtotal || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
              <td></td>
            </tr>}
            {signatureGrantView && <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}>Aquifer Special Discount</td>
              <td style={{backgroundColor: '#BAECFF'}}>{signatureGrantView}</td>
              <td></td>
            </tr>}
            {wiseGrantView && <tr className={'gen-select-item'}>
              <td></td>
              <td style={{textAlign: "right"}}>WISE Special Discount</td>
              <td style={{backgroundColor: '#BAECFF'}}>{wiseGrantView}</td>
              <td></td>
            </tr>}
            <tr className={'gen-select-item'}>
              <td style={{backgroundColor: '#E8E8E8'}}></td>
              <td style={{backgroundColor: '#E8E8E8', textAlign: "right"}}><b>{this.schoolYearDisplay()} Subscription Total</b></td>
              <td style={{backgroundColor: '#1B5599', color: 'white'}}><b>&nbsp;&nbsp;&nbsp;&nbsp;${(total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b></td>
              <td style={{backgroundColor: '#E8E8E8'}}></td>
            </tr>
            <tr>
              <td colSpan={2}>{is_admin && <GenPostButton
                key="saveQuote"
                name="Save Quote"
                highlighted
                disabled={renewalStage === 'Quote Confirmed'}
                url={updateQuoteUrl}
                data={{
                  ...buttonData
                }}
              />}</td>
              <td colSpan={2}>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>{button}</td>
              <td colSpan={2}>
                <div style={{float: 'left'}}>
                  <GenPostButton
                    key="SummaryPDF"
                    name="Download Quote PDF"
                    highlighted
                    url={downloadPDFUrl}
                    data={{
                      access_code: quoteInterface.access_code,
                      ...buttonData
                    }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }
}
