import React from 'react'

import _ from 'lodash'
import * as classNames from 'classnames'
import Cookies from 'js-cookie';


import GenLinkButton from 'Gen/LinkButton/component'
import GenPostButton from 'Gen/PostButton/component'
import GenSortTable from 'Gen/SortTable/component'
import GenSortLink from 'Gen/SortLink/component'
import { GenButton } from '../../Gen/components'

import {full_url} from 'helpers/url'
import {formatAsPrice} from 'helpers/number_format'
import {intToDateTimeString} from 'helpers/time_format'

export default function QuoteInterfaceTable(props) {
  const {
    quote_details,
    roleTypes,
    addictionCourseNames,
    paidSignatureCourseNames,
    freeSignatureCourseNames,
    wiseCourseNames,
    maxAddendumCount,
    schoolYearStart,
    schoolYearDisplay,
    lastSchoolYearDisplay,
    otherYearDashboardUrl,
    quoteInterfaceEditUrl,
    otherYearButtonTitle,
    allowTELActivation,
    showCalibrateActivateButton,
  } = props

  const maxAddendumCountRange = [...Array(maxAddendumCount).keys()].map(i => i+1)

  return <GenSortTable
    title={schoolYearDisplay}
    searchKeys={["name", "subdomain"]}
    searchPlaceholder="Search Programs..."
    extraButtons={[<GenButton
      key="quote_interface_dashboard"
      name={otherYearButtonTitle}
      highlighted
      onClick={() => {
       Cookies.set('dashboardYear', otherYearDashboardUrl.slice(-5));
        window.location.href = full_url(otherYearDashboardUrl) }}
    />,<GenPostButton
      key="refresh_from_zoho"
      name="Refresh All From ZOHO"
      highlighted
      url={`/quote_interfaces/refresh_all?current_school_year=${schoolYearStart}_${schoolYearStart+1}`}
    />]}
    columnWidths={(state) => {
      return _.concat(
        // Sticky Program Information
        [
          350,
          125,
        ],
        // More Program Information
        [
          105,
          150,
          125,
          200,
          300,
          200,
          300,
        ],
        // Subscription Process
        [
          150,
          225,
          175,
          140,
        ],
        _.times(3 * (maxAddendumCount + 1), _.constant(175)),
        [
          175,
        ],
        // Subscription Information
        [
          175,
          175,
        ],
        _.times(2 * (maxAddendumCount + 1), _.constant(175)),
        [
          175,
          175,
          175,
          175,
          175,
        ],
        // Signature Courses
        _.times(addictionCourseNames.length + paidSignatureCourseNames.length + freeSignatureCourseNames.length, _.constant(200)),
        // Wise Courses
        _.times(wiseCourseNames.length, _.constant(200)),
        // Roles
        _.times(addictionCourseNames.length, _.constant(175)),
        _.times(paidSignatureCourseNames.length, _.constant(175)),
        _.times(freeSignatureCourseNames.length, _.constant(175)),
        _.times(wiseCourseNames.length, _.constant(175)),
        _.times(roleTypes.length, _.constant(125)),
      )
    }}
    headerView={(state, updateSort) => {
      let scienceIisColumnCount = (schoolYearStart === 20) ? 0 : 1
      let telColumnCount        = 0
      let telColumnName         = null
      let signatureColumnWidth = addictionCourseNames.length + paidSignatureCourseNames.length + freeSignatureCourseNames.length + scienceIisColumnCount + telColumnCount
      if (allowTELActivation) {
        scienceIisColumnCount = 1
        telColumnCount        = 1
        telColumnName         = <th>Calibrate</th>
      }

      return [
        <tr key='header-1' className="sticky-row">
          <th colSpan={2} className="sticky-cell">Program Information</th>
          <th colSpan={7}></th>
          <th colSpan={5 + (3 * (maxAddendumCount + 1))}>Subscription Process</th>
          <th colSpan={8 + (2 * (maxAddendumCount + 1))}>Subscription Information</th>
          <th colSpan={schoolYearDisplay === "2023-24" ? signatureColumnWidth - 4 : signatureColumnWidth}>Signature Courses</th>
          <th colSpan={wiseCourseNames.length}>Wise Courses</th>
          <th colSpan={roleTypes.length}>Role Counts</th>
        </tr>,
        <tr key='header-2' className="sticky-row">
          <th rowSpan="2" className="sticky-cell"><GenSortLink value={"name"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Name</GenSortLink></th>
          <th rowSpan="2" className="sticky-cell" style={{'left': '350px'}}><GenSortLink value={"subdomain"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Subdomain</GenSortLink></th>
          <th rowSpan="2"></th>
          <th rowSpan="2"><GenSortLink value={"refreshedTimestamp"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Last Refresh</GenSortLink></th>
          <th rowSpan="2"><GenSortLink value={"customerStatus"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Customer Status</GenSortLink></th>
          <th rowSpan="2"><GenSortLink value={"businessDevelopmentManager"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Relationship Manager</GenSortLink></th>
          <th rowSpan="2">PSA Name</th>
          <th rowSpan="2">PSA Email</th>
          <th rowSpan="2">Billing Name</th>
          <th rowSpan="2">Billing Email</th>
          <th rowSpan="2"><GenSortLink value={"renewalStage"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Renewal Stage</GenSortLink></th>
          <th rowSpan="2">Action Button</th>
          <th rowSpan="2">Add Addendum</th>
          <th rowSpan="2">Run Updates</th>
          <th rowSpan="2"><GenSortLink value={"quoteSentTimestamp"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Quote Sent Timestamp</GenSortLink></th>
          <th rowSpan="2"><GenSortLink value={"quoteSubmittedTimestamp"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Quote Submitted Timestamp</GenSortLink></th>
          <th rowSpan="2"><GenSortLink value={"quoteConfirmedTimestamp"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Quote Confirmed Timestamp</GenSortLink></th>
          {maxAddendumCountRange.map(i => {
            return <>
              <th key={`addendum-sent-${i}`} rowSpan="2"><GenSortLink value={`addendumQuoteSentTimestamp${i}`} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Addendum {i} Sent Timestamp</GenSortLink></th>
              <th key={`addendum-submitted-${i}`} rowSpan="2"><GenSortLink value={`addendumQuoteSubmittedTimestamp${i}`} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Addendum {i} Submitted Timestamp</GenSortLink></th>
              <th key={`addendum-confirmed-${i}`} rowSpan="2"><GenSortLink value={`addendumQuoteConfirmedTimestamp${i}`} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Addendum {i} Confirmed Timestamp</GenSortLink></th>
            </>
          })}
          <th rowSpan="2">QI Button</th>
          <th rowSpan="2">Invoice Total <br/>{lastSchoolYearDisplay}</th>
          <th rowSpan="2">Invoice Addendums Total <br/>{lastSchoolYearDisplay}</th>
          <th rowSpan="2">Initial Total <br/>{schoolYearDisplay}</th>
          {maxAddendumCountRange.map(i => <th key={`addendum-initial-total-${i}`} rowSpan="2">Addendum {i} Initial Total <br/>{schoolYearDisplay}</th>)}
          <th rowSpan="2">Submitted Total <br/>{schoolYearDisplay}</th>
          {maxAddendumCountRange.map(i => <th key={`addendum-submitted-total-${i}`} rowSpan="2">Addendum {i} Submitted Total <br/>{schoolYearDisplay}</th>)}
          <th rowSpan="2"><GenSortLink value={"sharedSuccess"} sortValue={state.sortValue} sortDirection={state.sortDirection} onClick={updateSort}>Shared Success</GenSortLink></th>
          <th rowSpan="2">Price Category <br/>{schoolYearDisplay}</th>
          <th rowSpan="2">Price Tier <br/>{lastSchoolYearDisplay}</th>
          <th rowSpan="2">Price Tier <br/>{schoolYearDisplay}</th>
          <th rowSpan="2">Max Enrollment <br/>{lastSchoolYearDisplay}</th>
          <th rowSpan="2">Max Enrollment <br/>{schoolYearDisplay}</th>
          {addictionCourseNames.map((addiction_course_name) => <th key={addiction_course_name}>{addiction_course_name}</th>)}
          {paidSignatureCourseNames.map((paidSignature_course_name) => <th key={paidSignature_course_name}>{paidSignature_course_name}</th>)}
          {schoolYearDisplay === "2023-24" ? <th key={"Aquifer Oral Presentation Skills"}>Aquifer Oral Presentation Skills</th> : freeSignatureCourseNames.map((freeSignature_course_name) => <th key={freeSignature_course_name}>{freeSignature_course_name}</th>)}
          {telColumnName}
          {wiseCourseNames.map((wise_course_name) => <th key={wise_course_name}>{wise_course_name}</th>)}
          {roleTypes.map((role_type) => <th key={role_type}>{role_type}</th>)}
        </tr>
      ]
    }}
    rowView={(rowDatum) => {
      let actionButton = null
      if (rowDatum.renewalStage === 'Quote Confirmed' ||
        rowDatum.renewalStage === 'Ready to Confirm' ||
        rowDatum.renewalStage === 'Quote Sent') {
        actionButton = <GenPostButton
          key="reset_quote_button"
          name={`Reset ${ rowDatum.quotesCount > 1 ? `Addendum ${rowDatum.quotesCount - 1}` : 'Original'} Quote`}
          highlighted
          confirmMessage={"Are you sure you want to reset the quote for " + rowDatum.name + "?"}
          url={'/quote_interfaces/' + rowDatum.quoteInterfaceID + '/reset'}
        />
      } else if (rowDatum.renewalStage === 'Ready to Send') {
        actionButton = <GenPostButton
          key="send_quote_button"
          name={`Send ${ rowDatum.quotesCount > 1 ? `Addendum ${rowDatum.quotesCount - 1}` : 'Original'} Quote`}
          highlighted
          url={'/quote_interfaces/' + rowDatum.quoteInterfaceID + '/send_quote'}
        />
      }

      let activateTelButton = null
      let nextYearTelStatus = (!!rowDatum.paidSignatureCourseStatuses.find(s => s.key == "fm_price").future || !!rowDatum.paidSignatureCourseStatuses.find(s => s.key == "im_price").future || !!rowDatum.paidSignatureCourseStatuses.find(s => s.key == "rad_price").future || !!rowDatum.paidSignatureCourseStatuses.find(s => s.key == "peds_price").future || !!rowDatum.paidSignatureCourseStatuses.find(s => s.key == "neu_price").future)
      if (rowDatum.telStatus && nextYearTelStatus){
        activateTelButton = <td>YES</td>
      } else if(rowDatum.telStatus && !nextYearTelStatus){
        activateTelButton = <td className="groups-upload-revoke-course">REVOKE</td>
      } else if (!rowDatum.telStatus && nextYearTelStatus){
        activateTelButton = <td className="groups-upload-lend-course">LEND</td>
      } else {
        activateTelButton = <td>NO</td>
      }

      return <tr key={rowDatum.subdomain} className={classNames('groups-upload-row', {'groups-upload-new-program': rowDatum.isNewProgram, 'groups-upload-deactivate': rowDatum.activateChange === "DEACTIVATE"})}>
        <td className="sticky-cell">{rowDatum.name}</td>
        <td className="sticky-cell" style={{'left': '350px'}}>{rowDatum.subdomain}</td>
        <td>
          <GenPostButton
            name="Refresh"
            highlighted
            url={full_url("/quote_interfaces/" + rowDatum.quoteInterfaceID + "/refresh", {subdomain: rowDatum.subdomain})}
          />
        </td>
        <td style={{'fontSize': '12px'}}>{rowDatum.refreshedTimestamp && intToDateTimeString(rowDatum.refreshedTimestamp)}</td>
        <td>{rowDatum.customerStatus}</td>
        <td>{rowDatum.businessDevelopmentManager}</td>
        <td>{rowDatum.psaName}</td>
        <td>{rowDatum.psaEmail}</td>
        <td>{rowDatum.billingContactName}</td>
        <td>{rowDatum.billingContactEmail}</td>
        <td>{rowDatum.renewalStage}</td>
        <td>{actionButton}</td>
        <td>
          <GenPostButton
            name={`Add Addendum ${rowDatum.quotesCount}`}
            highlighted
            disabled={!rowDatum.isLastQuoteConfirmed || rowDatum.quotesCount > maxAddendumCount}
            url={'/quote_interfaces/' + rowDatum.quoteInterfaceID + '/add_addendum'}
          />
        </td>
        <td>
          <GenPostButton
            name={`Run Updates`}
            highlighted
            disabled={!rowDatum.quoteConfirmedTimestamp}
            confirmMessage={"Are you sure you want to run updates for " + rowDatum.name + "?"}
            url={'/quote_interfaces/' + rowDatum.quoteInterfaceID + '/run_updates'}
          />
        </td>
        <td>{rowDatum.quoteSentTimestamp && intToDateTimeString(rowDatum.quoteSentTimestamp)}</td>
        <td>{rowDatum.quoteSubmittedTimestamp && intToDateTimeString(rowDatum.quoteSubmittedTimestamp)}</td>
        <td>{rowDatum.quoteConfirmedTimestamp && intToDateTimeString(rowDatum.quoteConfirmedTimestamp)}</td>
        {maxAddendumCountRange.map(i => {
          return <>
            <td key={`addendum-sent-${i}`}>{rowDatum[`addendumQuoteSentTimestamp${i}`] && intToDateTimeString(rowDatum[`addendumQuoteSentTimestamp${i}`])}</td>
            <td key={`addendum-submitted-${i}`}>{rowDatum[`addendumQuoteSubmittedTimestamp${i}`] && intToDateTimeString(rowDatum[`addendumQuoteSubmittedTimestamp${i}`])}</td>
            <td key={`addendum-confirmed-${i}`}>{rowDatum[`addendumQuoteConfirmedTimestamp${i}`] && intToDateTimeString(rowDatum[`addendumQuoteConfirmedTimestamp${i}`])}</td>
          </>
        })}
        <td key='14'>
          <GenLinkButton
            key="quote_interface_link"
            name="Quote Interface"
            highlighted
            href={full_url(quoteInterfaceEditUrl, {subdomain: rowDatum.subdomain})}
          />
        </td>
        <td>{formatAsPrice(rowDatum.previousYearInvoiceTotal)}</td>
        <td>{formatAsPrice(rowDatum.previousYearAddendumTotal)}</td>
        <td>{formatAsPrice(rowDatum.initialTotal)}</td>
        {maxAddendumCountRange.map(i => <td key={`addendum-initial-total-${i}`}>{rowDatum[`addendumInitialTotal${i}`] && formatAsPrice(rowDatum[`addendumInitialTotal${i}`])}</td>)}
        <td>{(rowDatum.quoteSubmittedTimestamp) && formatAsPrice(rowDatum.total)}</td>
        {maxAddendumCountRange.map(i => <td key={`addendum-submitted-total-${i}`}>{(rowDatum[`addendumQuoteSubmittedTimestamp${i}`]) && formatAsPrice(rowDatum[`addendumTotal${i}`])}</td>)}
        <td>{rowDatum.sharedSuccess ? "Yes" : "No"}</td>
        <td>{rowDatum.priceCategory}</td>
        <td>{rowDatum.previousYearPriceTier}</td>
        <td>{rowDatum.priceTier}</td>
        <td>{rowDatum.currentMaxStudentEnrollment}</td>
        <td>{rowDatum.maxStudentEnrollment}</td>
        {rowDatum.addictionCourseStatuses.concat(
          rowDatum.paidSignatureCourseStatuses,
          rowDatum.freeSignatureCourseStatuses,
        ).map((courseStatus, i) => {
          if (courseStatus.change === "LEND") {
            return <td key={(2^i)*71} className="groups-upload-lend-course">LEND</td>
          } else if (courseStatus.change === "REVOKE") {
            return <td key={(2^i)*71} className="groups-upload-revoke-course">REVOKE</td>
          } else {
            if (courseStatus.current) {
              return <td key={(2^i)*71}>YES</td>
            } else {
              return <td key={(2^i)*71}>NO</td>
            }
          }
        })}
        {activateTelButton}
        {rowDatum.wiseCourseStatuses.map((courseStatus, i) => {
          if (courseStatus.change === "LEND") {
            return <td key={(2^i)*71} className="groups-upload-lend-course">LEND</td>
          } else if (courseStatus.change === "REVOKE") {
            return <td key={(2^i)*71} className="groups-upload-revoke-course">REVOKE</td>
          } else {
            if (courseStatus.current) {
              return <td key={(2^i)*71}>YES</td>
            } else {
              return <td key={(2^i)*71}>NO</td>
            }
          }
        })}
        {rowDatum.roleCounts.map((roleCount, i) => {
          if (roleCount) {
            if (roleCount.change === "INCREASE") {
              return <td key={(2^i)*79} className='groups-upload-increase-role-count'>{roleCount.current} {'->'} {roleCount.future}</td>
            } else if (roleCount.change === "DECREASE") {
              return <td key={(2^i)*79} className='groups-upload-decrease-role-count'>{roleCount.current} {'->'} {roleCount.future}</td>
            } else {
              return <td key={(2^i)*79}>{roleCount.current}</td>
            }
          } else {
            return <td key={(2^i)*79}></td>
          }
        })}
      </tr>
    }}
    rowDataGenerator={quote_details => {
      return Object.values(quote_details).map((quoteDetail) => {
        let renewalStage = ""
        let originalQuote = quoteDetail.quotes[0]
        let lastQuote = quoteDetail.quotes[quoteDetail.quotes.length-1]

        if (lastQuote.quote_confirmed_at) {
          renewalStage = 'Quote Confirmed'
        } else if (lastQuote.quote_submitted_at) {
          renewalStage = 'Ready to Confirm'
        } else if (lastQuote.quote_sent_at) {
          renewalStage = 'Quote Sent'
        } else {
          renewalStage = 'Ready to Send'
        }

        let priceLookup = {
          0: "Small",
          1: "Standard",
          2: "Large Caribbean/NP",
          3: "Exception"
        }

        let addendumState = {}
        maxAddendumCountRange.forEach(i => {
          let quote = quoteDetail.quotes[i]
          addendumState = {
            ...addendumState,
            [`addendumQuoteSentTimestamp${i}`]: quote && quote.quote_sent_at && Date.parse(quote.quote_sent_at),
            [`addendumQuoteSubmittedTimestamp${i}`]: quote && quote.quote_submitted_at && Date.parse(quote.quote_submitted_at),
            [`addendumQuoteConfirmedTimestamp${i}`]: quote && quote.quote_confirmed_at && Date.parse(quote.quote_confirmed_at),
            [`addendumInitialTotal${i}`]: quote && quote.initial_price,
            [`addendumTotal${i}`]: quote && quote.total,
          }
        })

        return {
          ...addendumState,
          customerStatus: quoteDetail.quote_interface.customer_status,
          businessDevelopmentManager: quoteDetail.quote_interface.business_development_manager,
          name: quoteDetail.group_name,
          subdomain:  quoteDetail.group_subdomain,
          quoteInterfaceID: quoteDetail.quote_interface.id,
          refreshedTimestamp: quoteDetail.quote_interface.refreshed_at && Date.parse(quoteDetail.quote_interface.refreshed_at),
          psaName: quoteDetail.quote_interface.psa_name,
          psaEmail: quoteDetail.quote_interface.psa_email,
          billingContactName: quoteDetail.quote_interface.billing_contact_name,
          billingContactEmail: quoteDetail.quote_interface.billing_contact_email,
          renewalStage: renewalStage,
          quoteSentTimestamp: originalQuote.quote_sent_at && Date.parse(originalQuote.quote_sent_at),
          quoteSubmittedTimestamp: originalQuote.quote_submitted_at && Date.parse(originalQuote.quote_submitted_at),
          quoteConfirmedTimestamp: originalQuote.quote_confirmed_at && Date.parse(originalQuote.quote_confirmed_at),
          initialTotal: originalQuote.initial_price,
          total: originalQuote.total,
          previousYearInvoiceTotal: quoteDetail.quote_interface.previous_year_invoice_total,
          previousYearAddendumTotal: quoteDetail.quote_interface.previous_year_addendum_total,
          quotesCount: quoteDetail.quotes.length,
          isLastQuoteConfirmed: !!lastQuote.quote_confirmed_at,
          priceCategory: {
            0: "Small",
            1: "Standard",
            2: "Large Caribbean/NP",
            3: "Exception"
          }[quoteDetail.quote_interface.price_category],
          previousYearPriceTier: quoteDetail.quote_interface.previous_year_price_tier,
          priceTier: quoteDetail.price_tier,
          currentMaxStudentEnrollment: quoteDetail.role_counts["Student"].current,
          maxStudentEnrollment: quoteDetail.quote_interface.max_enrollment,
          addictionCourseStatuses: addictionCourseNames.map(courseName => quoteDetail.addiction_course_statuses[courseName]),
          paidSignatureCourseStatuses: paidSignatureCourseNames.map(courseName => quoteDetail.signature_course_statuses[courseName]),
          freeSignatureCourseStatuses: schoolYearDisplay === "2023-24" ? [quoteDetail.free_course_statuses["Aquifer Oral Presentation Skills"]] : freeSignatureCourseNames.map(courseName => quoteDetail.free_course_statuses[courseName]),
          hasIllnessScriptCourses: _.values(quoteDetail.illness_script_course_statuses).filter(illnessScript => illnessScript.current).length > 0,
          wiseCourseStatuses: wiseCourseNames.map(courseName => quoteDetail.wise_course_statuses[courseName]),
          roleCounts: roleTypes.map(roleType => quoteDetail.role_counts[roleType]),
          illnessScriptCourseStatuses: quoteDetail.illness_script_course_statuses["Aquifer Sciences Integrated Illness Scripts"],
          telStatus: quoteDetail.tel_status,
          sharedSuccess: quoteDetail.shared_success,
        }
      })
    }}
    data={quote_details}
  />
}
