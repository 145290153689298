import React from 'react'

import GenSelectList from 'Gen/SelectList/component'
import GenButton from 'Gen/Button/component'

import { documentListDefaults } from 'Document/List/defaults'

export default class DocumentSetDocumentRelationDeleteSelectList extends React.Component {
  state = {
    selectedDocumentIds : [],
    loading: false
  };

  handleDocumentSelectionChange = (newSelection) => {
    this.setState({selectedDocumentIds : newSelection});
  };

  onSubmitSuccess = () => {
    var edit_url = window.location.href;
    var update_url = edit_url.replace("/document_set_document_relations/delete_batch_list", "");

    Turbo.visit(update_url);
  };

  handleSubmitButton = () => {
    if (this.state.selectedDocumentIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to remove.");
      return(null);
    }

    let data = {documentIds: this.state.selectedDocumentIds};

    let edit_url = window.location.href;
    let update_url = edit_url.replace("delete_", "destroy_");

    this.setState({loading: true});

    let outsideThis = this;
    $.ajax({
      type: "DELETE",
      url: update_url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onSubmitSuccess,
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        outsideThis.setState({loading: false});
      }
    });
  };

  render() {
    let documentSelectionView =  <GenSelectList
      {...documentListDefaults}
      selected={this.state.selectedDocumentIds}
      onSelectionChange={this.handleDocumentSelectionChange}
      itemsURL={"/document_sets/" + this.props.document_set_id + "/document_set_document_relations"}
      itemView={function(document){
        let dependencySign;
        if (document.is_prerequisite) {
          dependencySign = <b>* This prerequisite case can not be removed unless all of the subservient cases are removed from the course</b>;
        } else if (document.is_subservient) {
          dependencySign = <b style={{color: 'blue'}}>*</b>;
        }
        return(
          <div key={document.id}>
            <span>{document.title} {dependencySign}</span>
          </div>);
      }}
    />;



    return(
      <div className="document-set-document-relations-select-list">
        <div className="submit-panel">
          <h1>Remove Items From Course</h1>
          <GenButton
            name="Remove"
            highlighted={true}
            loading={this.state.loading}
            onClick={this.handleSubmitButton}
          />
        </div>
        <div>
          {documentSelectionView}
        </div>
      </div>
    );
  }
}
