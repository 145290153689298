import React from 'react'

import AssessmentRosteringCard from './card'

export default class AssessmentRosteringList extends React.Component {
  render() {
    return (
      <div className="panel-header">
        {Object.entries(this.props.rostering_details_list).map((entry, index) => {
          const [course, rosterings] = entry;
          return (
            <AssessmentRosteringCard course={course} rosterings={rosterings} />
          )
        })}
      </div>
    )
  }
}

