import React from 'react'
import { find, filter, concat } from 'lodash'
import * as classNames from 'classnames'

export default class GroupRoleDefinitionUserSummary extends React.Component {
  subscriptionStatus = () => {
    if (this.props.groupRoleDefinitionUser.subscription_end === null){
      return("Registration Pending");
    } else {
      var subscription_end_date = new Date(this.props.groupRoleDefinitionUser.subscription_end);
      if (subscription_end_date.getTime() > new Date('2100')) {
        return("Never Expires");
      } else if (subscription_end_date.getTime() > Date.now()) {
        return("Registered until:  " + subscription_end_date.toISOString().slice(0, 10));
      } else {
        return("Expired on: " + subscription_end_date.toISOString().slice(0, 10));
      }
    }
  };

  render() {
    const subscriptionStatus = this.subscriptionStatus().includes('Registration Pending') ? this.subscriptionStatus() : 'Registered'

    let variableColumn;
    let subscriptionExpiration;
    let lcaCourseColumn;
    switch (this.props.permanentFilters[0]) {
      case "=":
        variableColumn = this.props.groupRoleDefinitionUser.graduation_day;
        break;
      case "!=":
        variableColumn  = this.props.groupRoleDefinitionUser.primary_clinical_discipline.join('<br/>');
        lcaCourseColumn = this.props.groupRoleDefinitionUser.lca_courses;
        break;
      default:
        variableColumn = '';
        lcaCourseColumn = '';
        break;
    }

    const emailWidth = {
      overflow: 'hidden',
      wordWrap: 'break-word'
    };

    const user = this.props.groupRoleDefinitionUser.user;
    subscriptionExpiration = this.props.groupRoleDefinitionUser.subscription_end?.split('T')[0];

    return (
      <tr
        className={classNames('gen-select-item', { selected: this.props.selected })}
        onClick={this.props.onClick}
      >
        <td className="col_xsm">
          <input
            readOnly
            name="selected"
            type="checkbox"
            checked={this.props.selected}
            aria-label={user.email}
          />
        </td>
        <td style={emailWidth}>{user.first_name}</td>
        <td>{user.last_name}</td>
        <td style={emailWidth}>{user.email}</td>
        <td>{this.props.groupRoleDefinitionUser.role_definition.name}</td>
        {this.props.permanentFilters[0] === '!=' && <td>{lcaCourseColumn}</td>}
        <td dangerouslySetInnerHTML={{__html: variableColumn}} />
        <td>{subscriptionStatus}</td>
        <td>{this.props.groupRoleDefinitionUser.created_at.split('T')[0]}</td>
        <td>{subscriptionExpiration}</td>
        <td><a href={"/group_role_definition_users/" + this.props.groupRoleDefinitionUser.id + "/edit"} className="button">Edit Role</a></td>
      </tr>
    )
  }
}
