import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import GenModal from 'Gen/Modal/component'
import DocumentDetail from 'Document/Detail/component'
import ActionButton from 'Document/ActionButton/component'
import { TOGGLE_MODAL, activeModalSelector, documentIdSelector } from 'reducers/ui/documentSummary'
import * as classNames from 'classnames';

import { postDocumentUserLog, OPENED_MOD_MAP_LOG } from 'helpers/DocumentUserLog'

const ModMapModal = ({ document, toggleModal }) => (
  <GenModal open >
    <div className="gen-modal-panel">
      <h1>Mechanism of Disease Map</h1>
      <b dangerouslySetInnerHTML={{__html: document.title}} />
      <div className="mod-map-image">
        <img src={document.mod_map_image_url} />
      </div>
      <p>
        <button onClick={toggleModal(this)}>Close</button>
      </p>
    </div>
  </GenModal>
)

const searchHit = (content) => /search-hit/.test(content)
const sanitize = (content) => content && content.replace(/^"(.*)"$/, '$1')
const synopsisContentSelector = (doc) => sanitize(doc.case_synopsis)

const CaseSynopsisModal = ({ document, toggleModal }) =>
  <GenModal open >
    <div className="gen-modal-panel">
      <h1>Synopsis</h1>
      <b dangerouslySetInnerHTML={{__html: document.title}} />
      <hr/>
      <div dangerouslySetInnerHTML={{__html: synopsisContentSelector(document)}} />
      <button onClick={toggleModal(this)}>Close</button>
    </div>
  </GenModal>

const IllnessScriptSummary = ({
  activeModal,
  toggleModal,
  documentId,
  document,
  ...props
}) => (
  <DocumentDetail {...props} {...{document}} isIllnessScriptDocument>
    {document.id === documentId && activeModal}
    <ul className="unstyled">
      <li>
        <ActionButton onClick={ onSynopsisClick(document, toggleModal) }
                      icon="clipboard"
                      className={classNames({ 'search-hit': searchHit(synopsisContentSelector(document))})}>
          Synopsis
        </ActionButton>
      </li>
      <li>
        { document.mod_map_image_url && (
          <ActionButton
            onClick={(e) => {
              postDocumentUserLog(document.id, OPENED_MOD_MAP_LOG)
              analytics.track("click_on_view_mod_map", { case: document.title, source: 'Integrated Illness Script', page: window.location.href })
              toggleModal(<ModMapModal {...{document, toggleModal}}/>, document.id)(e)
            }}
            icon="project-diagram"
          >
            View MOD Map
          </ActionButton>
        )}
      </li>
      {!props.isIllnessScript && <li>{lastAccessedSummaryDate(document)}</li>}
    </ul>
  </DocumentDetail>
)

const lastAccessedSummaryDate = (document) => {
  if (document.progress) {
    return (document.progress.date_last_accessed) ? `Last Accessed: ${formattedDate(document.progress.date_last_accessed)}` : "Not Yet Accessed"
  }
}

const formattedDate = (date) => moment(date).format("MMMM Do YYYY")

const onSynopsisClick = (document, toggleModal) => {
  const toggleModalCallback = toggleModal(<CaseSynopsisModal {...{document, toggleModal}}/>, document.id)

  return (e) => {
    analytics.track("click_on_synopsis", {})
    toggleModalCallback(e)
  }
}

const mapStateToProps = (state) => ({
  activeModal: activeModalSelector(state),
  documentId: documentIdSelector(state)
})

const mapDispatchToProps = (dispatch, oProps) => ({
  toggleModal: (modal, documentId) => (e) => { e.preventDefault(); e.stopPropagation(); dispatch({ type: TOGGLE_MODAL, modal, documentId }) }
})

export default connect(mapStateToProps, mapDispatchToProps)(IllnessScriptSummary)
