import { call, put, select, takeLatest, retry } from 'redux-saga/effects'
import ReactOnRails from 'react-on-rails'
import axios from 'axios'
import {
  CREATE,
  CREATE_FAILED,
  CREATED,
  DOWNLOAD,
  DOWNLOAD_FAILED,
  RESET,
  urlSelector,
} from 'reducers/reports'

function* createReport(props) {
  try {
    const awsPath = yield call(startExport, props)
    const url = yield retry(100, 10 * 1000, checkExport, { awsPath, ...props })
    if(url) {
      yield put({type: CREATED, url})
    } else {
      yield put({type: CREATE_FAILED, msg: 'max retries exceeded when checking for report'})
    }
  } catch(e) {
    yield put({type: CREATE_FAILED, e})
  }
}

function* downloadReport() {
  try {
    const url = yield select(urlSelector)
    window.location.href = url
    yield put({type: RESET})
  } catch(e) {
    yield put({type: DOWNLOAD_FAILED, e})
  }
}

const startExport = ({createUrl, reportType}) => (
  axios.request({
    method: 'post',
    url: reportType.createUrl || createUrl,
    headers: ReactOnRails.authenticityHeaders({ 'Accept': 'application/json' }),
    data: { type: reportType.type },
  }).then(response => response.data.aws_path)
)

const checkExport = ({showUrl, reportType, awsPath}) => (
  axios.request({
    url: reportType.showUrl || showUrl,
    params: { aws_path: awsPath },
  }).then(response => {
    if(response.data.url){
      return response.data.url
    } else {
      throw 'caught upstream'
    }
  })
)

export default function* saga() {
  yield [
    yield takeLatest(CREATE, createReport),
    yield takeLatest(DOWNLOAD, downloadReport)
  ]
}
