import React from 'react'

import GenSelectList from 'Gen/SelectList/component'
import GenButton from 'Gen/Button/component'

import { documentListDefaults } from 'Document/List/defaults'

export default class GroupDocumentCreateBatchSelectList extends React.Component {
  state = {
    selectedGroupDocumentIds : [],
    selectedGroupId : this.props.groups[0].id,
    loading: false
  };

  handleGroupDocumentSelectionChange = (newSelection) => {
    this.setState({selectedGroupDocumentIds : newSelection});
  };

  handleGroupSelectionChange = (event) => {
    this.setState({selectedGroupId : event.target.value});
  };

  onSubmitSuccess = () => {
    var edit_url = window.location.href;
    var update_url = edit_url.replace("/group_documents/new_batch", "/documents");

    Turbo.visit(update_url);
  };

  handleSubmitButton = () => {
    if (this.state.selectedGroupDocumentIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to lend.");
      return(null);
    }

    let data = {groupDocumentIds: this.state.selectedGroupDocumentIds, groupId: this.state.selectedGroupId};

    let edit_url = window.location.href;
    let update_url = edit_url.replace("new_", "create_");

    this.setState({loading: true});

    let outsideThis = this;
    $.ajax({
      type: "POST",
      url: update_url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onSubmitSuccess,
      error: function(xhr, status, error) {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        outsideThis.setState({loading: false});
      }
    });
  };

  render() {
    let groupDocumentSelectList = <GenSelectList
        {...documentListDefaults}
        selected={this.state.selectedGroupDocumentIds}
        onSelectionChange={this.handleGroupDocumentSelectionChange}
        itemView={function(group_document){
          return(
            <div className="document-select-summary" key={`group-doc${group_document.id}`}>
              <span>{group_document.document.title}</span>
            </div>);
        }}
        itemsURL={"/group_documents/new_batch"}
      />;

    let groupOptions = this.props.groups.map(function(group){
      return(<option key={group.id} value={group.id}>{group.name}</option>);
    });

    return(
      <div className="document-set-document-relations-select-list">
        <div className="submit-panel">
          <h1>Lend Content to Program</h1>
          <GenButton
            name="Lend"
            highlighted={true}
            loading={this.state.loading}
            onClick={this.handleSubmitButton}
          />
        </div>
        <div className="panel">
          <label>Program To Lend To:</label>
          <select value={this.state.selectedGroupId} onChange={this.handleGroupSelectionChange}>
            {groupOptions}
          </select>
        </div>
        <div>
          {groupDocumentSelectList}
        </div>
      </div>
    );
  }
}
