import * as React from 'react';

import * as R from 'ramda';

import AqAutocompleteSelect from '../Aq/AutocompleteSelect';
import AqButton from '../Aq/Button';

interface ReportsFiltersProps {
  title: string;
  reportOptionsUrl: string;
  createReportUrl: string;
  initialFilterOptions?: Array<FilterOptionsGroup>;
  initialColumnOptions?: Array<ColumnOptionGroup>;
}

interface FilterOption {
  key: string;
  title: string;
  conditionFilter?: boolean;
  options_array: Array<[string, string]>;
}

interface FilterOptionsGroup {
  header: string;
  filter_options: Array<FilterOption>;
}

interface ColumnOption {
  key: string;
  title: string;
  required?: boolean;
  default?: boolean;
}

interface ColumnOptionGroup {
  header: string;
  column_options: Array<ColumnOption>;
}

type ColumnValues = Record<string, boolean>;
type FilterValues = Record<string, Array<{value: string, label: string}>>;

function getReportOptions(url: string, currentValues: { columnValues: ColumnValues, filterValues: FilterValues }, onSuccess: (filterOptions: Array<FilterOptionsGroup>, columnOptions: Array<ColumnOptionGroup>) => void) {
  const searchPrarms = new URLSearchParams();

  Object.keys(currentValues.columnValues).forEach((columnKey) => {
    if (currentValues.columnValues[columnKey]) {
      console.log("Column")
      searchPrarms.append(`c--${columnKey}`, 'true');
    }
  });

  Object.keys(currentValues.filterValues).forEach((filterKey) => {
    currentValues.filterValues[filterKey].forEach((filterValue) => {
      searchPrarms.append(`${filterKey}[]`, filterValue.value);
    });
  });

  fetch(url + '?' + searchPrarms.toString(), {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(async (response) => {
    return await response.json();
  }).then((json) => {
    onSuccess(json.filterOptions, json.columnOptions);
  });
}

function postReportOptions(url: string, currentValues: { columnValues: ColumnValues, filterValues: FilterValues }, onSuccess: () => void) {
  fetch(url, {
    method: "POST",
    headers: ReactOnRails.authenticityHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      filters: R.mapObjIndexed((val) => val.map((val1) => val1.value), currentValues.filterValues),
      columns: currentValues.columnValues,
    }),
  }).then(async (response) => {
    return await response.json();
  }).then((json) => {
    onSuccess();
  });
}

export default function ReportsFilters(props: ReportsFiltersProps) {
  const {
    title,
    initialFilterOptions,
    initialColumnOptions,
    reportOptionsUrl,
    createReportUrl,
  } = props;

  const [filterOptions, setFilterOptions] = React.useState<Array<FilterOptionsGroup>>(initialFilterOptions);
  const [filterValues, setFilterValues] = React.useState<FilterValues>({});

  const [columnOptions, setColumnOptions] = React.useState<Array<ColumnOptionGroup>>(initialColumnOptions);
  const [columnValues, setColumnValues] = React.useState<ColumnValues>({});

  React.useEffect(() => {
    getReportOptions(
      reportOptionsUrl,
      { columnValues, filterValues },
      (filterOptions, columnOptions) => {
        setFilterOptions(filterOptions);
        setColumnOptions(columnOptions);

        const optionPairs = columnOptions
          .flatMap((columnOptionGroup) => columnOptionGroup.column_options)
          .filter((columnOption) => columnOption.default || columnOption.required)
          .map((columnOption) => [columnOption.key, true] as [string, boolean])

        setColumnValues(R.fromPairs(optionPairs))
      }
    );
  }, []);

  if (!filterOptions || !columnOptions) { return; }

  return (
    <div>
      <div>
        {
          filterOptions.map((filterOptionGroup) => (
            <div key={filterOptionGroup.header} style={{margin: "20px 0"}}>
              <h4>{filterOptionGroup.header} Filters</h4>
              <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: "10px"}}>
              {
                filterOptionGroup.filter_options.map((filterOption) => (
                  <div key={filterOption.key}>
                    <label>{filterOption.title}</label>
                    <AqAutocompleteSelect<{value: string, label: string}, true>
                      placeholder={`Pick a ${filterOption.title}`}
                      multiple
                      options={filterOption.options_array.map((opt) => ({ value: opt[1], label: opt[0] }))}
                      value={filterValues[filterOption.key] || []}
                      onChange={(event, newValue) => {
                        const newFilterValues =  { ...filterValues, [filterOption.key]: newValue };
                        setFilterValues(newFilterValues);
                        if (filterOption.conditionFilter) {
                          getReportOptions(
                            reportOptionsUrl,
                            { columnValues, filterValues: newFilterValues },
                            (filterOptions, columnOptions) => {
                              setFilterOptions(filterOptions);
                              setColumnOptions(columnOptions);
                            }
                          );
                        }
                      }}
                    />
                  </div>
                ))
              }
              </div>
            </div>
          ))
        }
      </div>

      <div style={{margin: "25px 0"}}>
        <h3>Columns</h3>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: "10px"}}>
          {
            columnOptions.map((columnOptionGroup) => (
              <div key={columnOptionGroup.header}>
                <h4>{columnOptionGroup.header}</h4>

                {
                  columnOptionGroup.column_options.map((columnOption) => (
                    <div key={columnOption.key}>
                      <label>
                        <input
                          type="checkbox"
                          disabled={columnOption.required}
                          checked={!!columnOption.required || columnValues[columnOption.key]}
                          onChange={(e) => { setColumnValues({ ...columnValues, [columnOption.key]: e.target.checked }); }}
                        />
                        {columnOption.title}
                      </label>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>

      <div style={{margin: "25px 0"}}>
        <AqButton
          onClick={() => {
            postReportOptions(
              createReportUrl,
              { columnValues, filterValues },
              () => { Turbo.visit('/archived_reports') }
            )
          }}
        >
          Download Report
        </AqButton>
      </div>
    </div>
  );
}
