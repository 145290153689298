import PropTypes from 'prop-types'
import React from 'react'
import { map } from 'lodash'

import GenList from 'Gen/List/component'


export default class GenSelectList extends React.Component {
  // React.PropTypes
  static propTypes = {
    selected: PropTypes.array.isRequired,
    itemsURL: PropTypes.string.isRequired,
    onSelectionChange: PropTypes.func.isRequired, // [Int] -> null
    itemView: PropTypes.func.isRequired, // Item -> View
    // selectExtraButtons: React.PropTypes.
  }

  onItemClick = (item) => (e) => {
    e.stopPropagation()

    let selectedIds = this.props.selected
    let newSelected = []
    let prereqId = item.prerequisite_document_id
    let subIds = item.sub_ids
    if (window.location.href.indexOf('new_batch_list') > -1) {
      if (selectedIds.indexOf(item.id) >= 0) {
        if (!subIds || !selectedIds.some(r => subIds.indexOf(r) >= 0)) {
          newSelected = selectedIds.filter((id) => id !== item.id)
        } else {
          newSelected = selectedIds
          alert("You can not deselect this case as any of its subsverient case is selected in the list.")
        }
      } else {
        newSelected = selectedIds.concat([item.id])
        if (prereqId !== undefined && prereqId !== null && selectedIds.indexOf(prereqId) < 0) {
          newSelected = newSelected.concat([prereqId])
        }
      }
    } else {
      if (selectedIds.indexOf(item.id) >= 0) {
        newSelected = selectedIds.filter(function(id){ return(id !== item.id); });
      } else {
        newSelected = selectedIds.concat([item.id]);
      }
    }

    this.props.onSelectionChange(newSelected)
  }

  generateView = (item) => {
    if(this.props.tableHeader) {
      return this.props.itemView({...item, onClick: this.onItemClick(item).bind(this)})
    }

    let classes = 'gen-select-item'
    if (this.props.selected.indexOf(item.id) >= 0) {
      classes += ' selected'
    }

    return (
      <div
        className={`${classes} ${(window.location.href.indexOf('delete_batch_list') > -1) && item.is_prerequisite ? 'prereq-sign' : ''}`}
        onClick={this.onItemClick(item)}
        key={item.id}
      >
        {this.props.itemView(item)}
      </div>
    )
  }

  selectAll = () => {
    const currentItems = this.childGenList.state.currentItems || []
    let itemIds = map(currentItems, 'id')

    const prerequisiteDocumentIds = currentItems.map(doc => doc.prerequisite_document?.id).filter(x => x);
    if (prerequisiteDocumentIds.length > 0) {
      itemIds = itemIds.concat(prerequisiteDocumentIds)
    }

    this.props.onSelectionChange(itemIds)
  }

  deselectAll = () => {
    this.props.onSelectionChange([])
  }

  selectionButtons = () => {
    if (this.props.useSelectionBasket) {
      return (
        <button onClick={this.selectAll} id={'add_all_shown_btn'}>
          Add {this.childGenList && this.childGenList.state.currentItems ? this.childGenList.state.currentItems.length : 'All'} Shown
        </button>
      )
    }

    return (
      <span>
        <button onClick={this.selectAll}>Select All Shown</button>
        <button onClick={this.deselectAll}>Deselect All</button>
      </span>
    )
  }

  selectPanel = (extra) => !this.props.noSelectPanel && (
    <div className="gen-select-list-buttons">
      <div className="gen-select-list-buttons-left">
        {this.selectionButtons()}
        {extra}
      </div>
      <div className="gen-select-list-buttons-right">
        {this.props.selectExtraButtons}
      </div>
    </div>
  )

  render() {

    return(<div className="gen-select-list">
      <GenList
        ref={(input) => { this.childGenList = input }}
        {...this.props}
        selectPanel={this.selectPanel}
        itemView={this.generateView.bind(this)}
      />
    </div>)
  }
}
